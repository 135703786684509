import React, {useState} from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../style/cardslider.css'
import {loadStripe} from "@stripe/stripe-js";
import {toast, ToastContainer} from "react-toastify";
import {UserAuth} from "../components/AuthContext";
import Signup from "../components/Signup";

document.body.style.backgroundImage = 'url("https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/navy bg for GBCD.png")';

const CheckoutPass = () => {
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [password, setPassword] = useState('');
    const [reEnteredPassword, setReEnteredPassword] = useState('');
    const {createUser, signIn} = UserAuth();
    const [stripeError, setStripeError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [usernameErrorMessage, setUsernameErrorMessage] = useState("")
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("")

    const myUser = {
        "email": email,
        "firstName": firstName,
        "lastName": lastName
    }

    const postNewUser = async () => {
        try {
            const url = process.env.REACT_APP_B_KEND + '/ostuser';

            await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(myUser),
            }).then(response => response.json())
                .then(data => data);
        } catch (error) {
            toast.error(error.message);
        }
    };

    let stripePromise
    const getStripe = () => {
        if (!stripePromise) {
            stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
        }
        return stripePromise;
    }

    const item = {
        price: process.env.REACT_APP_DIGITAL_PASS,
        quantity: 1
    };

    const checkoutOptions = {
        lineItems: [item],
        mode: "subscription",
        successUrl: `${window.location.origin}/post-sale-signed-in?sessionid=${process.env.REACT_APP_DIGITAL_PASS}`,
        cancelUrl: `${window.location.origin}/checkout`
    };
    const redirectToCheckout = async () => {
        const stripe = await getStripe();
        const {error} = await stripe.redirectToCheckout(checkoutOptions);
        if (error) setStripeError(error.message);
        if (stripeError) alert(stripeError);
        setIsLoading(false);
    }
    const onLogin = async (e) => {
        e.preventDefault();
        try {
            await signIn(email, password);
        } catch (e) {
            toast.error(e.message);
        }
    }
    const onSubmit = async (e) => {
        e.preventDefault()

        toast.info("Creating User", {autoClose: 1000})
        // textValidation();

        setIsLoading(true);

        if (password === reEnteredPassword
            && validateLoginDetails()
            && validatePasswordMatches()) {

            try {
                await createUser(email, password)
                postNewUser();
                onLogin();


                await redirectToCheckout();
            } catch (e) {
                const errorMessage = e.message;
                const errorDisplayMessage = errorMessage.toString().replace("Firebase: ", "");
                toast.error(errorDisplayMessage);
                setIsLoading(false);

            }
        } else {
            toast.error("Passwords don't match");
        }
    }

    const validateLoginDetails = () => {
        if (firstName.length > 1 &&
            lastName.length > 1) {
            setUsernameErrorMessage("");
            return true;
        } else {
            setUsernameErrorMessage("* Please Ensure Your First & Last Name Are At Least 2 Letters");
            toast.error("Please Ensure Your First & Last Name Are At Least 2 Letters");
            setIsLoading(false);
            return false;
        }
    }


    const validatePasswordMatches = () => {
        if (password === reEnteredPassword &&
            password.length > 7
            && reEnteredPassword.length > 7) {
            setPasswordErrorMessage("");
            return true;
        } else {
            setPasswordErrorMessage("* Please Ensure Your Password's Match & Are Longer Than 7 Characters");
            toast.error("Please Ensure Your Password's Match & Are Longer Than 7 Characters");
            setIsLoading(false);
            return false;
        }
    }

    return (
        <div className="col-md-10 col-12 mx-auto align-items-center">
            <h1 className={"text-center"}>Digital Pass Checkout</h1>
            <div className="col-10 col-md-6 mx-auto card bg-white border-white ">
                {
                    <Signup email={email}
                            setEmail={setEmail}
                            firstName={firstName}
                            setFirstName={setFirstName}
                            lastName={lastName}
                            setLastName={setLastName}
                            password={password}
                            setPassword={setPassword}
                            reEnteredPassword={reEnteredPassword}
                            setReEnteredPassword={setReEnteredPassword}
                            headerMessage={"1. Sign Up"}
                    />
                }

                <div><p className={"text-danger text-center"}>{usernameErrorMessage}</p></div>
                <div><p className={"text-danger text-center"}>{passwordErrorMessage}</p></div>
                <div className={"col-12 mx-auto text-center"}>
                    {
                        isLoading === true ?
                            (
                                <button
                                    className="col-6 mt-1 oswald-font btn btn-lg btn-warning text-bold rounded-3  rounded-pill mb-3"
                                    onClick={onSubmit}>
                                    LOADING ...
                                </button>
                            )
                            :
                            (
                                <button
                                    className="col-6 mt-1 oswald-font btn btn-lg btn-primary text-bold rounded-3  rounded-pill mb-3"
                                    onClick={onSubmit}>
                                    CHECKOUT
                                </button>
                            )
                    }
                </div>

            </div>


            <ToastContainer
                className="toast-position"
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"/>
        </div>);
};
export default CheckoutPass;