import React from "react";

const DESKTOP_PRODUCT_REVIEW_SETTINGS = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <></>, // Empty React fragment to remove previous arrow
    nextArrow: <></>, // Empty React fragment to remove next arrow

};

const MOBILE_PRODUCT_REVIEW_SETTINGS = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <></>, // Empty React fragment to remove previous arrow
    nextArrow: <></>, // Empty React fragment to remove next arrow
};

export {MOBILE_PRODUCT_REVIEW_SETTINGS, DESKTOP_PRODUCT_REVIEW_SETTINGS}