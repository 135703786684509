
import React from 'react';
import useSound from 'use-sound';

const SoundButton = ({ soundFile }) => {
    const [play, { isPlaying }] = useSound(soundFile, { format: ['m4a'] });

    const handleButtonClick = () => {
        if (!isPlaying) {
            play();
        }
    };

    return (
        <div>
            <button className="bg-transparent" onClick={handleButtonClick} disabled={isPlaying}>
                <img
                    src="https://oststorage.blob.core.windows.net/ostpstrblb/app/icons/sound_icon.png" 
                    alt="Sound Button"
                    style={{ width: '50px', height: '50px' }}
                    className={"pulsating"}
                />
            </button>
        </div>
    );
};

export default SoundButton;
