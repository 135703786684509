import React, {Component} from 'react';
import {Container} from 'reactstrap';
import {NavMenu} from './NavMenu';
import {Link} from "react-router-dom";


export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                {/*<div className="countdown-timer"><Timer duration={1690095550000}/></div>*/}
                {/*Temporarily removed top banner*/}
                {/*<div className="col-12 countdown-timer"><p><Link style={{ textDecoration: 'none' }} to={"/buy-now"} className={"text-navy"}>WELCOME TO OUR TESTING PROGRAM</Link></p></div>*/}
                <NavMenu/>
                <Container>
                    {this.props.children}
                </Container>
            </div>
        );
    }
}
