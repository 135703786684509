const Book = ({ image, bookCode, isReleased = false, testBookCode, readBookFunc }) => {
    const renderButton = () => {
        let buttonClass = "book-button"; // Add a custom class for styling
        if (!isReleased) {
            return <button className={`comingsoon-item-button ${buttonClass}`}>COMING SOON</button>;
        }
        if (bookCode !== '') {
            return <button className={`read-item-button ${buttonClass}`} onClick={() => readBookFunc(bookCode)}>READ</button>;
        }
        if (testBookCode === '') {
            return <button className={`refresh-item-button ${buttonClass}`}>REFRESH</button>;
        }
        return <button className={`sale-item-button ${buttonClass}`}>UNLOCK</button>;
    };

    return (
        <div className="book-item login-book-read">
            <div className="home-read-button">
                <img
                    className="login-book book-cover"
                    src={image}
                    alt="book button"
                    onClick={() => readBookFunc(bookCode)}
                />
            </div>
            <div className="mx-auto text-center">
                {renderButton()}
            </div>
        </div>
    );
};

export default Book;
