import React, {useState} from 'react'
import Fullpage, {FullPageSections} from '@ap.cx/react-fullpage'
import ImageWithText from "./ImageWithText";
import Carousel from 'react-bootstrap/Carousel';
import {Link} from "react-router-dom";
import {Image} from "react-bootstrap";
import {CircularProgressbar} from "react-circular-progressbar";
import {BOOK_PROGRESS_CIRCLE_GRAPH_STYLE, HOME_BUTTON_STYLE} from "../utils/BookReaderStylesUtils";
import TurnDevicePopUp from "./TurnDevicePopUp";
import BookConfetti from "./BookConfetti";


const PostSaleBookSlider = ({books}) => {

    var bookLength = books.pageList.length;
    let myList = [];
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    let endBookMessage;



    const handlePageTurn = (selectedIndex, e) => {
        setCurrentPageNumber(selectedIndex + 1);
    };
    
    for (let i = 1; i <= books.pageList.length; i++) {
        myList.push(i); // add elements to the array
    }

    if (currentPageNumber === 1) {
        TurnDevicePopUp();
    }

    if (currentPageNumber === books.pageList.length) {
        endBookMessage = <BookConfetti/>;
    }
    

    
    return (
        <Fullpage>
            <FullPageSections>
                {endBookMessage}
                {/*Home button*/}
                <span style={HOME_BUTTON_STYLE}>
            <Link to={"/signed-in"} className={"remove-underlink"}>
                <Image src={"https://oststorage.blob.core.windows.net/ostpstrblb/app/icons/home.png"}
                       style={{width: '50px', height: '50px', zIndex: "1000"}}
                />
                </Link>
            </span>

                {/*progress bar*/}
                <span style={BOOK_PROGRESS_CIRCLE_GRAPH_STYLE} className={"progressBarContainer"}>
                <CircularProgressbar
                    value={currentPageNumber}
                    maxValue={bookLength}
                    text={`${currentPageNumber}/${bookLength}`}
                    strokeWidth={15}
                    styles={{
                        path: {stroke: '#4CB1B5'},
                        trail: {stroke: 'grey'},
                        background: {fill: '#fff'},
                        text: {fill: '#4CB1B5'}
                    }}
                    backgroundPadding={20}/>
            </span>
                <Carousel
                    interval={null}
                    indicators={true} // Show slide indicators
                    wrap={false} // Disable infinite looping
                    onSelect={handlePageTurn} //update the page number
                    className="full-screen-carousel">
                    {myList.map((number, index) => (
                        <Carousel.Item key={index}>
                            <ImageWithText imageUrl={books.pageList[number - 1].imageUrl}
                                           text={books.pageList[number - 1].pageText.replace(/%20/g, " ")}
                                           pos={books.pageList[number - 1].pageTextConfiguration}
                                           fontFamily={books.fontFamily}
                                           textMetadata={books.pageList[number - 1].pageTextConfiguration}
                            />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </FullPageSections>
        </Fullpage>
    );
};

export default PostSaleBookSlider;