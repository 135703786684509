import React from 'react';

const HardCoverBookSelector = ({
                                   schoolBookImage,
                                   birthdayBookImage,
                                   zooBookImage,
                                   bookOneSelected,
                                   buttonOneIsSelected,
                                   handleBookOneClicked,
                                   handleBookOneChange,
                                   buttonTwoIsSelected,
                                   handleBookTwoClicked,
                                   buttonThreeIsSelected,
                                   handleBookThreeClicked,
                                    headerMessage
                               }) => { 
    return (
        <div className={"text-center bg-white"}>
            <div className={"book-selector-heading"}>
                <h1 className={"col-8 text-center mx-auto login-header"}>{headerMessage}</h1>
                <h5 >Click On The Story You Would Like To Purchase A Hard Cover For</h5>
            </div>
            <div className={"row mx-auto col-md-10 col-12 login-book-div"}>
                <div className={`col-lg-4 login-book-read ${buttonOneIsSelected ? 'selected-border' : 'disabled'}`}
                     onClick={handleBookOneClicked}>
                    <div className={"home-read-button mt-3"}>
                        <img className={"three-quarter-width-height-image"} src={schoolBookImage} alt={"book cover"}/>
                    </div>
                    <div className={"home-read-button"}>
                        <label className={'col-2'}>
                            <input
                                className={'input-big'}
                                type="radio"
                                value="first-day"
                                checked={bookOneSelected === 'first-day'}
                                onChange={handleBookOneChange}
                            />
                        </label>
                    </div>
                    <h5>My Fantastic First Day At School</h5>

                    <div className={"home-read-button"}>
                        <p className={"product-text"}>
                            Follow your child’s journey through excitement and nerves on their fantastic first day at
                            school! </p>
                    </div>
                    <div className={"mx-auto p-2 col-6 bg-primary rounded-3 mb-3"}>
                        <strong className={"text-white"}>AGES 4-6</strong>
                    </div>
                </div>
                <div className={`col-lg-4 login-book-read ${buttonTwoIsSelected ? 'selected-border' : 'disabled'}`}
                     onClick={handleBookTwoClicked}>
                    <div className={"home-read-button mt-3"}>
                        <img className={"three-quarter-width-height-image"} src={birthdayBookImage} alt={"book cover"}/>
                    </div>
                    <div className={"home-read-button"}>
                        <label className={'col-2'}>
                            <input
                                className={'input-big'}
                                type="radio"
                                value="birthday"
                                checked={bookOneSelected === 'birthday'}
                                onChange={handleBookOneChange}
                            />
                        </label></div>
                    <h5>My Brilliant Birthday</h5>

                    <div className={"home-read-button"}>

                        <p className={"product-text"}>
                            Join the adventure of the most brilliant birthday bash ever! Filled with surprises and joy
                            this will be a birthday that won't be forgotten.</p>
                    </div>
                    <div className={"mx-auto p-2 col-6 bg-primary rounded-3 mb-3"}>
                        <strong className={"text-white"}>AGES 4-6</strong>
                    </div>
                </div>
                <div className={`col-lg-4 login-book-read ${buttonThreeIsSelected ? 'selected-border' : 'disabled'}`}
                     onClick={handleBookThreeClicked}>


                    <div className={"home-read-button mt-3"}>
                        <img className={"three-quarter-width-height-image"} src={zooBookImage} alt={"book cover"}/>
                    </div>
                    <div className={"home-read-button mx-auto text-center"}>
                        <label className={'col-2'}>
                            <input
                                className={'input-big'}
                                type="radio"
                                value="zoo"
                                checked={bookOneSelected === 'zoo'}
                                onChange={handleBookOneChange}
                            />
                        </label>
                    </div>

                    <h5>My Amazing Day At The Zoo</h5>
                    <div className={"home-read-button"}>
                        <p className={"product-text"}>
                            Embark on an amazing zoo escapade with Mum, meeting friendly animals and having a
                            roaring good time!</p>
                    </div>
                    <div className={"mx-auto p-2 col-6 bg-primary rounded-3 mb-3"}>
                        <strong className={"text-white"}>AGES 4-6</strong>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HardCoverBookSelector;