
const hardCoverProductImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/buy-now/product_hardcover_square.png";
const businessProductImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/buy-now/business_product_offering.png";
const digitalProductImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/buy-now/product_digital_square.png";

const freeDigitalProductImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/buy-now/product_free_digital_book.png";
const bonusHardcoverProductImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/buy-now/product_offer_bonus_hardcover.png";
const characterProductImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/buy-now/product_offer_150_characters.png";
const digitalLibraryProductImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/buy-now/product_offer_digital_library.png";


const PRODUCT_OFFERS_LIST = [
    {
        id: 1,
        title: 'Business Product Offering',
        image: businessProductImg,
        description: 'Our Storytime',
        heading: 'Free Plan',
        text: '',
        imageUrl: businessProductImg,
        promoText: '$',
        bannerColor: 'rgba(0, 0, 0, 0)',
        textColor: 'rgba(0, 0, 0, 0)',
        discountBackgroundColour: '#091E3B',
        discountTextColour: '#FFF',
        discountText: 'x1 Free Online Story',
        price: '',
        priceTextColour: '#091E3B',
        itemOne: 'Tailored stories for a unique experience',
        itemTwo: 'Add your logo to our designs',
        itemThree: 'Make your school, childcare or community event standout',
        itemList: [
            "Free access to the ‘My Brilliant Birthday’ custom online story on the Our Storytime Platform",
            "150+ Character Combinations"]

        ,
        imageList: [
            freeDigitalProductImg
        ],
        linkLocation: "/checkout-trial",
        buttonText: 'GET STARTED'
    },
    {
        id: 2,
        title: 'Digital Product Offering',
        image: digitalProductImg,
        description: 'My Story Pass',
        heading: 'MONTHLY PLAN',
        text: '',
        imageUrl: digitalProductImg,
        promoText: '$',
        bannerColor: 'rgba(0, 0, 0, 0)',
        textColor: 'rgba(0, 0, 0, 0)',
        discountBackgroundColour: '#091E3B',
        discountTextColour: '#FFF',
        discountText: 'Standard Plan',
        price: '$4.99/MONTH',
        // oldPrice: '$19.99',
        priceTextColour: '#091E3B',
        itemList: [ 
            "Access ALL online custom stories on the Our Storytime Platform", 
            "150+ Character Combinations",
            "Access to NEW Release Stories",
            "EXCLUSIVE members only premium hardcover book printing prices"]

        ,
        imageList: [
            digitalLibraryProductImg,
            characterProductImg

        ],
        itemOne: '1-YEAR ACCESS to all custom stories and NEW releases',
        itemTwo: '150 Character Combinations',
        itemThree: 'ONLINE ONLY hardcover sold separately',
        linkLocation: '/checkout-digital-pass',
        buttonText: 'GET STARTED'
    },
    {
        id: 3,
        title: 'Hard Cover Product Offering',
        image: hardCoverProductImg,
        description: 'Description for Course 1',
        heading: 'Annual Plan',
        text: '',
        imageUrl: hardCoverProductImg,
        promoText: 'RECOMMENDED',
        bannerColor: '#FFD289',
        textColor: '#091E3B',
        discountBackgroundColour: '#FF3131',
        discountTextColour: '#FFF',
        discountText: '+ Bonus Free Hardcover',
        price: '$59.99/YEAR',
        priceTextColour: '#FF3131',
        // oldPrice: '$124.99',
        itemOne: '1 Premium Hard Cover Custom Storybook',
        itemTwo: '1-YEAR ACCESS to all custom stories, 150 combinations and new releases',
        itemThree: 'FREE SHIPPING',
        itemList: [
            "x1 Free Premium Hardcover Book from our range. Includes shipping",
            "Access ALL Online custom stories on the Our Storytime Platform",
            "150+ Character Combinations",
            "Access to NEW Release Stories ",
            "EXCLUSIVE members only Premium Hardcover book printing prices "
            ]
        ,
        imageList: [
            bonusHardcoverProductImg,
            digitalLibraryProductImg,
            characterProductImg
        ],
        linkLocation: "/checkout-hard-cover-pass",
        buttonText: 'GET STARTED'
    }



];

export{PRODUCT_OFFERS_LIST}