import React, {useEffect, useState} from 'react';
import {Link, NavLink, useNavigate} from 'react-router-dom';
import {UserAuth} from "./AuthContext";
import ImageSlider from "./ImageSlider";

const PostSaleHardCoverSignedIn = () => {
    const {user, logout} = UserAuth();
    const navigate = useNavigate();
    const [promo, setPromo] = useState('');
    const [error, setError] = useState('');


    const userBook = {
        "bookName": "book",
        "purchaseCode": promo,
        "email": user.email
    };


    useEffect(() => {
        const hello = user.email;
        // getPromo();
        const url = window.location.href;
        const urlObj = new URL(url);
        const searchParams = new URLSearchParams(urlObj.search);
        const paramValue = searchParams.get('sessionid');
        setPromo(paramValue);
        // postPurchase(paramValue);


        const postPurchase = async (paramValue) => {
            try {
                const url = process.env.REACT_APP_B_KEND + '/checkout';

                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "bookName": "book",
                        "purchaseCode": paramValue,
                        "email": user.email

                    }),
                }).then(response => response.json())
                    .then(data => data);

                const data = await response;
                setError(null);
            } catch (error) {
                // Handle error
                setError(error.message);
            }
        };
        if (user !== undefined && user.email !== undefined) {
            postPurchase(paramValue);
            navigate('/signed-in');
        }
    }, [user]);
    //http://localhost:3000/signed-in?session_id=bladshdsad
    const getPromo = () => {
        const url = window.location.href;
        const urlObj = new URL(url);
        const searchParams = new URLSearchParams(urlObj.search);
        const paramValue = searchParams.get('sessionid');
        setPromo(paramValue);
    }


    const handleLogout = async () => {
        try {
            await logout();
            navigate('/');
        } catch (e) {
            console.log(e.message())

        }
    }
    return (
        <div>
            <h1 className={"text-center"}>Loading ....</h1>
            <h3 className={"text-center text-white"}>Please Don't Refresh Or Press Back</h3>
        </div>
    );
};

export default PostSaleHardCoverSignedIn;
