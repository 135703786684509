import React from 'react';

const BookReaderErrorPage = (props) => {
    const {errorMessage} = props;
    return (
        <div className={"error-page-container"}>
            <div className={"col-lg-8 col-11 error-page-box"}>
                <div className={"error-page-content"}>
                    <h1 className={"error-page-heading"}>Unable To Connect...</h1>
                    <img className={"error-page-image"}
                         src={"https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/error_logo.png"}
                         width={"40%"}
                         alt={"error"}/>
                    <h5 className={"error-page-text"}>Unfortunately you are unable to generate a book. Please check your
                        internet connection</h5>
                    <p className={"error-page-text"}>Error: {errorMessage}</p>
                </div>
            </div>

        </div>
    );
};

export default BookReaderErrorPage;