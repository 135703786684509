import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { UserAuth } from "./AuthContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "./firebase";

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    document.body.style.backgroundImage = 'url("https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/navy bg for GBCD.png")';

    const handlePasswordReset = async (e) => {
        toast.info("Submitting password reset", { autoClose: 1000 })
        e.preventDefault();
        setError('');
        try {
            await sendPasswordResetEmail(auth, email);
            setEmailSent(true);
        } catch (e) {
            setError(e.message);
        }
    };

    return (
        <div >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className='forgotten-password-card'>
                    <h2 className="login-header" style={{ textAlign: 'center' }} >Forgot your Password?</h2>

                    {!emailSent &&
                        <form onSubmit={handlePasswordReset} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p className="login-text text-sm">
                                Please enter your email address to recieve a password reset link.
                            </p>
                            <label className={"login-text"} htmlFor="email-address">
                                YOUR EMAIL ADDRESS
                            </label>

                            <input
                                className={"login-input"}
                                style={{ width: '70%' }}
                                id="email-address"
                                name="email"
                                type="email"
                                required
                                placeholder=""
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button
                                className={" btn login-button"}
                                style={{ width: '70%' }}
                                type='submit'
                            >
                                SEND RESET PASSWORD
                            </button>
                        </form>
                    }
                    {emailSent && 
                    <><br /><p>We have sent a reset password link to your email address. <a href="/"> Back to home. </a></p></>
                    }
                </div>
            </div>

            <ToastContainer
                className="toast-position"
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark" />
        </div>
    )
}

export default ForgotPassword