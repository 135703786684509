import React from 'react';
import SoundButton from "./SoundButton";
import 'react-circular-progressbar/dist/styles.css';


function getTextStyle(text, pos, fontFamily, textMetadata) {
    const basePageStyle = {
        position: 'absolute',
        top: '15%',
        left: '10%',
        transform: 'translate(-50%, -50%)',
        textAlign: "center",
        width: '20%',
    };

    if (text === 'theend') {
        return {
            ...basePageStyle,
            backgroundColor: "rgba(255, 255, 255, 0)",
        };

    } else {
        //Metadata to configure different text styles 
        return {
            ...basePageStyle,
            marginTop: '10px',
            width: textMetadata.textBoxWidth,
            top: textMetadata.textVerticalPosition,
            left: textMetadata.textHorizontalPosition,
            fontSize: textMetadata.textSize,
            color: textMetadata.textColour,
            fontFamily: fontFamily,
            fontWeight: 'bold',
            paddingBottom: "5px",
            paddingTop: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            backgroundColor: textMetadata.fillColour,
            borderRadius: textMetadata.borderRadius,
            borderTop: textMetadata.borderTopThickness,
            borderLeft: textMetadata.borderLeftThickness,
            borderRight: textMetadata.borderRightThickness,
            borderBottom: textMetadata.borderBottomThickness,
        };
    }
}

function ImageWithText({imageUrl, text, pos, fontFamily, textMetadata}) {
    return (
        <div className={"col-12 "} style={{position: 'relative'}}>
            {/*This is needed for transparent pages*/}
            {/*<div className="white-image-background"></div>*/}

            <img src={imageUrl} alt="book" className={"book-page"}/>
            {(() => {
                // if a page has sound display sound button
                if (textMetadata.isSound === "true") {
                    return <span style={getTextStyle(text, "50%", fontFamily, textMetadata)}>
                        <SoundButton
                            soundFile={textMetadata.soundFile}/>
                        <p className={"mt-2 pulsating"}>Click Me</p>
                    </span>
                }
                //If it is a text page display the text
                else if (text !== '') {
                    return <span style={getTextStyle(text, pos, fontFamily, textMetadata)}>
                    {text}
                </span>
                }
            })()}

        </div>
    );
}

export default ImageWithText;