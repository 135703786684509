import React from 'react';


const Signup = ({email, setEmail, firstName, setFirstName, lastName, setLastName,
                    password, setPassword, reEnteredPassword, setReEnteredPassword, headerMessage}) => {
    
    return (
        <div className={"col-lg-10 col-10 mx-auto bg-white text-center "}>
            <table className={"mx-auto"}>
                <thead>
                <tr>
                    <th>
                        <div>
                            <div className={"login-text-box"}>
                                <h1 className="login-header ">{headerMessage}</h1>
                                <p>Prior to purchasing the books you will need to make an account</p>
                            </div>

                            <form>
                                <div>
                                    <div>
                                        <label className={"login-text"} htmlFor="email-address">
                                            First Name
                                        </label>
                                    </div>

                                    <input
                                        className={"login-input col-10 col-md-6"}
                                        type="input"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        required
                                        placeholder="   ENTER FIRST NAME"
                                    />
                                </div>

                                <div>
                                    <div>
                                        <label className={"login-text"} htmlFor="email-address">
                                            LAST NAME
                                        </label>
                                    </div>

                                    <input
                                        className={"login-input col-10 col-md-6"}
                                        type="input"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        required
                                        placeholder="   ENTER LAST NAME"
                                    />
                                </div>
                                
                                <div>
                                    <div>
                                        <label className={"login-text"} htmlFor="email-address">
                                            EMAIL ADDRESS
                                        </label>
                                    </div>

                                    <input
                                        className={"login-input col-10 col-md-6"}
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        placeholder="   ENTER EMAIL ADDRESS"
                                    />
                                </div>

                                <div>
                                    <div>
                                        <label className={"login-text"} htmlFor="password">
                                            PASSWORD
                                        </label>
                                    </div>
                                    <input
                                        className={"login-input col-10 col-md-6"}
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                        placeholder="   ENTER PASSWORD"
                                    />
                                </div>
                                
                                <div>
                                    <div>
                                        <label className={"login-text"} htmlFor="re-password">
                                            RE-ENTER PASSWORD
                                        </label>
                                    </div>
                                    <input
                                        className={"login-input col-10 col-md-6"}
                                        type="password"
                                        value={reEnteredPassword}
                                        onChange={(e) => setReEnteredPassword(e.target.value)}
                                        required
                                        placeholder="RE-ENTER PASSWORD"
                                    />

                                    <p className={""}> By proceeding you are agreeing to our <a
                                        href="/privacy-policy" target="_blank"
                                        rel="noopener noreferrer">
                                        privacy policy
                                    </a></p>
                                </div>
                            </form>
                        </div>
                    </th>
                </tr>
                </thead>
            </table>
        </div>
    );
}

export default Signup;