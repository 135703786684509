import React from 'react';

const OstFooter = () => {
    return (
        <div>
            <div className={"mx-auto col-2 col-md-1 text-center mt-3 mb-3"}>
                <a href={"https://www.instagram.com/ourstorytime.au/?utm_source=qr"}>
                    <img className={"text-center mx-auto offset-4 col-4 col-md-2 footer-icon mx-auto"} src={"https://oststorage.blob.core.windows.net/ostpstrblb/app/icons/icon_instagram.png"} alt="find us on instragram ourstorytime.au"/>
                </a>
            </div>

            <div className={"mx-auto  col-6 col-md-2 text-center mt-3"}>
                <div className={"row col-12 mx-auto"}>
                    <img className={"col-4 footer-icon mx-auto"} src={"https://oststorage.blob.core.windows.net/ostpstrblb/app/icons/mastercard.png"} alt="accept mastercard payments"/>

                    <img className={"col-4  footer-icon mx-auto"} src={"https://oststorage.blob.core.windows.net/ostpstrblb/app/icons/visa.png"} alt="accept visa payments"/>

                    <img className={"col-4  footer-icon mx-auto"} src={"https://oststorage.blob.core.windows.net/ostpstrblb/app/icons/apple pay.png"} alt="accept apple pay payments"/>
                </div>
            </div>

            <div className={"mx-auto  col-12 col-md-4 text-center mt-3 mb-3"}>
                <div className={"row text-white col-12 mx-auto p-2"}>
                    <a className={"col-4 text-white mx-auto"}
                       href="/terms-of-service">
                    <p className={" text-white mx-auto"}>Terms of Service</p>
                    </a>

                    <a className={"col-4 text-white mx-auto"}
                       href="/faq">
                    <p className={"text-white mx-auto"}>FAQs</p>
                    </a>
                    <a className={"col-4 text-white mx-auto"}
                        href="/privacy-policy">
                    <p className={"text-white mx-auto"}>Privacy Policy</p>
                    </a>
                </div>
            </div>
            <div className={"footer-message"}>
                <p className='character-selector'>&copy; Our Storytime 2024</p>
            </div>
        </div>
    );
};

export default OstFooter;