import React, {useState, useRef, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../style/cardslider.css'
import {loadStripe} from "@stripe/stripe-js";
import {UserAuth} from "./AuthContext";
import {toast, ToastContainer} from "react-toastify";

document.body.style.backgroundImage = 'url("https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/navy bg for GBCD.png")';
const image1 = "https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/school_cover.png";
const image2 = "https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/birthday_cover.png";
const image3 = "https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/zoo_cover.png";


const Checkout = () => {
    const [bookOneSelected, setBookOneSelected] = useState("first-day");
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [password, setPassword] = useState('');
    const [reEnteredPassword, setReEnteredPassword] = useState('');
    const [, setError] = useState('');
    const {createUser, signIn} = UserAuth();
    const [buttonOneIsSelected, setButtonOneIsSelected] = useState(true);
    const [buttonTwoIsSelected, setButtonTwoIsSelected] = useState(false);
    const [buttonThreeIsSelected, setButtonThreeIsSelected] = useState(false);
    const [, setTextIsValid] = useState(false);
    const [stripeError, setStripeError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [priceLink, setPriceLink] = useState("");

    
    const myUser = {
        "email": email,
        "firstName": firstName,
        "lastName": lastName
    }

    const postNewUser = async () => {
        try {
            const url = process.env.REACT_APP_B_KEND + '/ostuser';

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(myUser),
            }).then(response => response.json())
                .then(data => data);
            setError(null);
        } catch (error) {
            // Handle error
            setError(error.message);
        }
    };

    let stripePromise
    const getStripe = () => {
        if (!stripePromise) {
            stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
        }
        return stripePromise;
    }

    const item = {
        price: priceLink,
        quantity: 1
    };

    const checkoutOptions = {
        lineItems: [item],
        mode: "payment",
        successUrl: `${window.location.origin}/post-sale-signed-in?sessionid=${priceLink}`,
        cancelUrl: `${window.location.origin}/checkout`
    };
    const redirectToCheckout = async (stripe_value) => {
        const stripe = await getStripe();
        const {error} = await stripe.redirectToCheckout(checkoutOptions);
        if (error) setStripeError(error.message);
        if (stripeError) alert(stripeError);
        setIsLoading(false);
    }

    const handleBookOneClicked = () => {
        setBookOneSelected("first-day");
        setPriceLink(process.env.REACT_APP_SCHOOL_BOOK);
    };

    const handleBookTwoClicked = () => {
        setBookOneSelected("birthday");
        setPriceLink(process.env.REACT_APP_BIRTHDAY_BOOK);

    };

    const handleBookThreeClicked = () => {
        setBookOneSelected("zoo");
        setPriceLink(process.env.REACT_APP_ZOO_BOOK);

    };

    const handleBookOneChange = (event) => {
        setBookOneSelected(event.target.value)
    };

    useEffect(() => {
        if (bookOneSelected === "first-day") {
            setButtonOneIsSelected(true);
            setButtonTwoIsSelected(false);
            setButtonThreeIsSelected(false);
        } else if (bookOneSelected === "birthday") {
            setButtonOneIsSelected(false);
            setButtonTwoIsSelected(true);
            setButtonThreeIsSelected(false);
        } else {
            setButtonOneIsSelected(false);
            setButtonTwoIsSelected(false);
            setButtonThreeIsSelected(true);
        }
    })

    const textValidation = () => {
        if (firstName.length > 1 && lastName.length > 1) {
            setTextIsValid(true);
        } else {
            toast.error("Names input are not right length");
        }
    }

    const onLogin = async (e) => {
        e.preventDefault();
        setError('')
        try {
            await signIn(email, password);
        } catch (e) {
            setError(e.message)
        }
    }


    const onSubmit = async (e) => {
        e.preventDefault()

        toast.info("Creating User", {autoClose: 1000})
        // textValidation();

        setIsLoading(true);

        if (password === reEnteredPassword) {

            try {
                await createUser(email, password)
                postNewUser();
                onLogin();


                // navigate('/signed-in')
                await redirectToCheckout();
            } catch (e) {
                setError(e.message)
                const errorCode = e.code;
                const errorMessage = e.message;
                const errorDisplayMessage = errorMessage.toString().replace("Firebase: ", "");
                toast.error(errorDisplayMessage);
                setIsLoading(false);

            }
        } else {
            toast.error("Passwords don't match");
        }
    }

    const bookSelector = () => {
        return (<div className={"container"}>

            <div className={"book-selector-heading"}>
                <h2 className={"logged-in-title"}>1. Select Your Story</h2>
                <h5>Click On The Story You Would Like To Purchase</h5>
            </div>
            <div className={"row col-12 login-book-div "}>
                <div className={`col-lg-4 login-book-read ${buttonOneIsSelected ? 'selected-border' : 'disabled'}`}
                     onClick={handleBookOneClicked}>
                    <div className={"home-read-button"}>
                        <img className={"login-book book-cover"} src={image1} alt={"book cover"}/>
                    </div>
                    <div className={"home-read-button"}>
                        <label className={'col-2'}>
                            <input
                                className={'input-big'}
                                type="radio"
                                value="first-day"
                                checked={bookOneSelected === 'first-day'}
                                onChange={handleBookOneChange}

                            />
                        </label>
                    </div>
                    <h5>My Fantastic First Day At School</h5>

                    <div className={"home-read-button"}>
                        <p className={"product-text"}>
                            Follow your child’s journey through excitement and nerves on their fantastic first day at
                            school! <strong>AGES 4-6</strong></p>
                    </div>
                </div>
                <div className={`col-lg-4 login-book-read ${buttonTwoIsSelected ? 'selected-border' : 'disabled'}`}
                     onClick={handleBookTwoClicked}>
                    <div className={"home-read-button"}>
                        <img className={"login-book book-cover"} src={image2} alt={"book cover"}/>
                    </div>
                    <div className={"home-read-button"}>
                        <label className={'col-2'}>
                            <input
                                className={'input-big'}
                                type="radio"
                                value="birthday"
                                checked={bookOneSelected === 'birthday'}
                                onChange={handleBookOneChange}
                            />
                        </label></div>
                    <h5>My Brilliant Birthday</h5>

                    <div className={"home-read-button"}>

                        <p className={"product-text"}>
                            Join the adventure of the most brilliant birthday bash ever! Filled with surprises and joy
                            this will be a birthday that won't be forgotten. <strong>AGES 4-6</strong></p>
                    </div>
                </div>
                <div className={`col-lg-4 login-book-read ${buttonThreeIsSelected ? 'selected-border' : 'disabled'}`}
                     onClick={handleBookThreeClicked}>


                    <div className={"home-read-button"}>
                        <img className={"login-book book-cover"} src={image3} alt={"book cover"}/>
                    </div>
                    <div className={"home-read-button mx-auto text-center"}>
                        <label className={'col-2'}>
                            <input
                                className={'input-big'}
                                type="radio"
                                value="zoo"
                                checked={bookOneSelected === 'zoo'}
                                onChange={handleBookOneChange}
                            />
                        </label></div>

                    <h5>My Amazing Day At The Zoo</h5>
                    <div className={"home-read-button"}>
                        <p className={"product-text"}>
                            Embark on an amazing zoo escapade with Mum, meeting friendly animals and having a
                            roaring good time! <strong>AGES 4-6</strong></p>
                    </div>
                </div>
            </div>
        </div>);
    };

    const preSignIn = () => {
        return (
            <div className={"container col-12"}>
                <div className={"col-lg-7 col-10 mx-auto"}>
                    <table className={"mx-auto"}>
                        <thead>
                        <tr>
                            <th>
                                <div>
                                    <div className={"login-text-box"}>
                                        <h2 className="login-header">2. Sign Up</h2>
                                        <p>Prior to purchasing the books you will need to make an account</p>
                                    </div>

                                    <form>
                                        <div>
                                            <div>
                                                <label className={"login-text"} htmlFor="email-address">
                                                    First Name
                                                </label>
                                            </div>

                                            <input
                                                className={"login-input col-10 col-md-6"}
                                                type="input"
                                                label="First Name"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                required
                                                placeholder="   ENTER FIRST NAME"
                                            />
                                        </div>

                                        <div>
                                            <div>
                                                <label className={"login-text"} htmlFor="email-address">
                                                    LAST NAME
                                                </label>
                                            </div>

                                            <input
                                                className={"login-input col-10 col-md-6"}
                                                type="input"
                                                label="LAST NAME"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                                required
                                                placeholder="   ENTER LAST NAME"
                                            />
                                        </div>


                                        <div>
                                            <div>
                                                <label className={"login-text"} htmlFor="email-address">
                                                    EMAIL ADDRESS
                                                </label>
                                            </div>

                                            <input
                                                className={"login-input col-10 col-md-6"}
                                                type="email"
                                                label="Email address"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                                placeholder="   ENTER EMAIL ADDRESS"
                                            />
                                        </div>

                                        <div>
                                            <div>
                                                <label className={"login-text"} htmlFor="password">
                                                    PASSWORD
                                                </label>
                                            </div>
                                            <input
                                                className={"login-input col-10 col-md-6"}
                                                type="password"
                                                label="Create password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                                placeholder="   ENTER PASSWORD"
                                            />
                                        </div>


                                        <div>
                                            <div>
                                                <label className={"login-text"} htmlFor="re-password">
                                                    RE-ENTER PASSWORD
                                                </label>
                                            </div>
                                            <input
                                                className={"login-input col-10 col-md-6"}
                                                type="password"
                                                label=" Re-Enter Password"
                                                value={reEnteredPassword}
                                                onChange={(e) => setReEnteredPassword(e.target.value)}
                                                required
                                                placeholder="RE-ENTER PASSWORD"
                                            />
                                            <div>
                                                <p> By proceeding you are agreeing to our <a
                                                    href="/privacy-policy"
                                                    target="_blank" rel="noopener noreferrer">
                                                    privacy policy
                                                </a></p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </th>
                        </tr>
                        </thead>
                    </table>

                    <ToastContainer
                        // className="toast-position"
                        // position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"/>
                </div>
            </div>);
    };


    const slidesData = [{
        id: 1, title: 'Course 1', description: 'Description for Course 1'
    }, {
        id: 2, title: 'Course 2', description: 'Description for Course 2'
    }];

    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef();
    const navigate = useNavigate();

    const nextSlide = () => {
        if (currentSlide === 1) {
            // navigate('/character-selector'); 
        } else {
            window.scrollTo({top: 0, behavior: 'smooth'});
            sliderRef.current.slickNext();

        }
    };

    const goToSlide = (index) => {
        setCurrentSlide(index);
        sliderRef.current.slickGoTo(index);
    };

    const settings = {
        infinite: false, speed: 0, slidesToShow: 1, slidesToScroll: 1, prevArrow: <></>, // Empty React fragment to remove previous arrow
        nextArrow: <></>, // Empty React fragment to remove next arrow
        adaptiveHeight: true,
        beforeChange: (_, nextSlide) => setCurrentSlide(nextSlide),
    };

    return (
        <div className=" col-12 mx-auto align-items-center">
            <h1 className={"text-center"}>Checkout</h1>
            <div className="bah col-12 mx-auto checkout-card">
                <Slider ref={sliderRef} {...settings}>
                    {slidesData.map((slide) => (<div key={slide.id} className="checkout-card mx-auto text-center">
                        {slide.id === 1 ? bookSelector() : preSignIn()}
                    </div>))}
                </Slider>
            </div>

            <div className="slide-indicators">
                {slidesData.map((_, index) => (<div
                    key={index}
                    className={`slide-indicator ${index === currentSlide ? 'active' : ''}`}
                    onClick={() => goToSlide(index)}
                ></div>))}
            </div>

            <div className={"mx-auto text-center"}>
                {currentSlide !== slidesData.length - 1 ? (
                        <button className="col-md-3 col-6 btn btn-lg cardslider-button text-center" onClick={nextSlide}>
                            NEXT
                        </button>) :
                    isLoading === true ?
                        <button className="col-md-3 col-6 btn btn-lg loading-button" onClick={onSubmit}>
                            LOADING ...
                        </button> : <button className="col-md-3 col-6 btn btn-lg cardslider-button" onClick={onSubmit}>
                            CHECKOUT
                        </button>
                }


            </div>
        </div>);
};
export default Checkout;