import React from 'react'
import Swal from "sweetalert2";

const TurnDevicePopUp = () => {
    const html = `
    <div style="display: flex; flex-direction: column; align-items: center;">
      <img className="" src="/images/turn_phone.gif" style="width: 100%; height: 100%;" alt={"book reader guide"}/>
    </div>
  `;
    Swal.fire({
        title: '',

        html: html,
        imageAlt: 'Custom Image', // set the alt text of the image
        confirmButtonColor: "#0074D9",
        confirmButtonText: 'READ BOOK',
        color: 'red',
        background: 'red',
        customClass: {
            popup: 'popup-style',
            title: 'custom-title-class',
            content: 'custom-content-class',
            confirmButton: 'confirm-button',
        }
    });
};


export default TurnDevicePopUp;
