import React from 'react';


const TrialSignup = ({email, setEmail,
                    password, setPassword, reEnteredPassword, setReEnteredPassword, headerMessage}) => {
    
    return (
        <div className={"col-12 mx-auto bg-white text-center"}>
            <table className={"col-12 mx-auto"}>
                <thead>
                <tr>
                    <th>
                        <div>
                            <div>
                                <h3 className={"col-10 mx-auto login-header negative-20-margin-top"}>Create Account</h3>
                            </div>

                            <form>
                                <div className={"mx-auto"}>
                                    <div>
                                        <label className={"mx-auto mt-2 login-text"} htmlFor="email-address">
                                            EMAIL ADDRESS
                                        </label>
                                    </div>

                                    <input
                                        className={"mx-auto login-input col-10"}
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        placeholder="   ENTER EMAIL ADDRESS"
                                    />
                                </div>

                                <div>
                                    <div>
                                        <label className={"login-text"} htmlFor="password">
                                            PASSWORD
                                        </label>
                                    </div>
                                    <input
                                        className={"login-input col-10"}
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                        placeholder="   ENTER PASSWORD"
                                    />
                                </div>
                                
                                <div>
                                    <div>
                                        <label className={"login-text"} htmlFor="re-password">
                                            RE-ENTER PASSWORD
                                        </label>
                                    </div>
                                    <input
                                        className={"login-input col-10"}
                                        type="password"
                                        value={reEnteredPassword}
                                        onChange={(e) => setReEnteredPassword(e.target.value)}
                                        required
                                        placeholder="RE-ENTER PASSWORD"
                                    />

                                    <p className={"mt-1"}> By proceeding you are agreeing to our <a
                                        href="/privacy-policy" target="_blank"
                                        rel="noopener noreferrer">
                                        privacy policy
                                    </a></p>
                                </div>
                            </form>
                        </div>
                    </th>
                </tr>
                </thead>
            </table>
        </div>
    );
}

export default TrialSignup;