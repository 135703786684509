import React from 'react';

const BuyHardCoverBookForm = () => {
    return (
        <div className={"text-center form-colour"}>
            <iframe className={"rounded col-10 google-form"}
                src="https://docs.google.com/forms/d/1OykxcBJObe6uqg5ysqXKS_jof-CIQOB7lCDXmKRFcSA/viewform?edit_requested=true"
                title="External Page" 
            ></iframe>
        </div>
    );
};

export default BuyHardCoverBookForm;