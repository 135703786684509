// ApplicationInsightsProvider.js

import { ApplicationInsights } from '@microsoft/applicationinsights-web';


export default class ApplicationInsightsProvider {
  constructor(connectionString) {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: connectionString,
        enableAutoRouteTracking: true, 
        autoTrackPageVisitTime: true,
        enableDebug: false, 
      }
    });
    this.appInsights.loadAppInsights();
  }

  trackEvent(event, properties) {
    this.appInsights.trackEvent({ name: event, properties });
  }

  trackPageView(name, url) {
    this.appInsights.trackPageView({ name, uri: url });
  }

  trackException(exception) {
    this.appInsights.trackException({ exception });
  }

  trackMetric(name, value) {
    this.appInsights.trackMetric({ name, average: value });
  }

  setUserContext(userId) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }
  
}
