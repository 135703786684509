import React from 'react';

function CustomBookMessage({bookMessage, setBookMessage, selectedBookImage, headerMessage}) {
    return (
        <div className={"mx-auto"}>
            <div className={"login-text-box"}>
                <h2 className="login-header col-md-6 col-10 mx-auto text-center">{headerMessage}</h2>
            </div>
            <div>
                <h3>Book Preview</h3>
            </div>
            <div>
                <img className={"col-6 col-md-3 mb-3"} src={selectedBookImage}/>
            </div>
            <div className={"col-10 col-md-6 text-center mx-auto"}>
                <h5>Include a personal message (FREE)</h5>
                <div className="form-group">
                        <textarea
                            type="input"
                            label="bookMessage"
                            value={bookMessage}
                            onChange={(e) => setBookMessage(e.target.value)}
                            required
                            placeholder="Please enter a custom message"
                            className="form-control" rows="5" id="comment"></textarea>
                    <label htmlFor="comment"></label>
                    <p><strong>Messages need to be between 10 and 250 characters</strong></p>
                </div>
            </div>
        </div>
    );
}
export default CustomBookMessage;