import BookSlider from "./components/BookSlider";
import BookSelector from "./components/BookSelector";
import Signup from "./components/Signup";
import ForgotPassword from "./components/ForgotPassword";
import CardSlider from "./components/CardSlider";
import Checkout from "./components/Checkout";
import PostSaleHardCoverSignedIn from "./components/PostSaleHardCoverSignedIn";
import BuyHardCoverBookForm from "./pages/forms/BuyHardCoverBookForm";
import InstitutionalCustomerForm from "./pages/forms/InstitutionalCustomerForm";
import ProductFeedbackForm from "./pages/forms/ProductFeedbackForm";
import TermsOfService from "./pages/knowledge/TermsOfService";
import PrivacyPolicy from "./pages/knowledge/PrivacyPolicy";
import Faq from "./pages/knowledge/Faq";
import Home from "./pages/Home";
import BuyNow from "./pages/BuyNow";
import CheckoutPass from "./pages/CheckoutPass";
import CheckoutHardCoverPass from "./pages/CheckoutHardCoverPass";
import CharacterSelectorTryDemo from "./pages/CharacterSelectorTryDemo";
import Login from "./pages/Login";
import ErrorPage from "./pages/error/ErrorPage";
import BookReader from "./pages/BookReader";
import TrialBookReader from "./pages/TrialBookReader";
import CheckoutTrial from "./pages/CheckoutTrial";
import PostSaleTrialSignedIn from "./components/PostSaleTrialSignedIn";
import UpgradePlanForm from "./pages/forms/UpgradePlanForm";


const AppRoutes = [
    {
        index: true,
        element: <Home/>
    },
    {
        index: '/home',
        element: <Home/>
    },
    {
        path: '/character-selector-try-demo',
        element: <CharacterSelectorTryDemo/>
    },
    {
        path: '/book-reader',
        element: <BookReader/>
    },
    {
        path: '/trial-book-reader',
        element: <TrialBookReader/>
    }

    ,
    {
        path: '/book-slider',
        element: <BookSlider/>
    },
    {
        path: '/book-selector',
        element: <BookSelector/>
    },

    {
        path: '/signup',
        element: <Signup/>
    },
    {
        path: '/login',
        element: <Login/>
    },
    {
        path: '/buy-now',
        element: <BuyNow/>
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword/>
    },
    {
        path: '/error-page',
        element: <ErrorPage/>
    },

    {
        path: '*',
        element: <ErrorPage/>
    },
    {
        path: '/card-slider',
        element: <CardSlider/>
    },
    {
        path: '/checkout',
        element: <Checkout/>
    },
    {
        path: '/checkout-trial',
        element: <CheckoutTrial/>
    },
    {
        path: '/checkout-digital-pass',
        element: <CheckoutPass/>
    },
    {
        path: '/checkout-hard-cover-pass',
        element: <CheckoutHardCoverPass/>
    },
    {
        path: '/faq',
        element: <Faq/>
    },
    {
        path: '/privacy-policy',
        element: <PrivacyPolicy/>
    },
    {
        path: '/product-feedback-form',
        element: <ProductFeedbackForm/>
    },
    {
        path: '/institutional-customer-form',
        element: <InstitutionalCustomerForm/>
    },
    {
        path: '/post-sale-hardcover-signed-in',
        element: <PostSaleHardCoverSignedIn/>
    },
    {
        path: '/buy-hard-cover-book-form',
        element: <BuyHardCoverBookForm/>
    },
    {
        path: "/terms-of-service",
        element: <TermsOfService/>
    },
    {
        path: "/post-sale-trial-signed-in",
        element: <PostSaleTrialSignedIn/>
    },
    {
        path: "/upgrade-plan-form",
        element: <UpgradePlanForm/>
    }
];

export default AppRoutes;
