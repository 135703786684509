import React, {useEffect, useState} from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../style/cardslider.css'
import {toast, ToastContainer} from "react-toastify";
import {UserAuth} from "../components/AuthContext";
import CharacterSelector from "../components/CharacterSelector";
import TrialSignup from "../components/TrialSignup";
import {Navigate, useNavigate} from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";

const CheckoutTrial = () => {

    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [password, setPassword] = useState('');
    const [reEnteredPassword, setReEnteredPassword] = useState('');
    const {createUser, signIn} = UserAuth();
    const [hairColour, setHairColour] = useState("black");
    const [skinTone, setSkinTone] = useState("fair");
    const [hairStyle, setHairStyle] = useState("buzzhair");
    const [characterName, setCharacterName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("")
    const [characterNameErrorMessage, setCharacterNameErrorMessage] = useState("")
    const navigate = useNavigate();
    const priceLink = process.env.REACT_APP_BIRTHDAY_BOOK;


    const myUser = {
        "email": email,
        "firstName": firstName,
        "lastName": lastName,
        "characterName": characterName,
        "skinTone": skinTone,
        "hairStyle": hairStyle,
        "hairColour": hairColour,
        "bookSelected": "",
        "bookMessage": ""
    }

    const postNewUser = async () => {
        try {
            const url = process.env.REACT_APP_B_KEND + '/osthardcoveruser';

            await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(myUser),
            }).then(response => response.json())
                .then(data => data);
        } catch (error) {
            toast.error(error.message);
        }
    };

    const validateCharacterName = () => {
        if (characterName.length > 2) {
            setCharacterNameErrorMessage("");
            return true;
        } else {
            setCharacterNameErrorMessage("* Please Enter Your Custom Character's Name");
            toast.error("Please Enter Your Custom Character's Name");
            setIsLoading(false)
            return false;
        }
    }

    const onLogin = async (e) => {
        e.preventDefault();
        try {
            await signIn(email, password);
        } catch (e) {
            toast.error(e.message);
        }
    }
    const onSubmit = async (e) => {
        e.preventDefault()

        toast.info("Creating User", {autoClose: 1000})

        setIsLoading(true);

        if (password === reEnteredPassword
            && validateCharacterName()
            && validatePasswordMatches()) {

            try {
                await createUser(email, password)
                postNewUser();
                onLogin();
                navigate(`/post-sale-trial-signed-in?sessionid=${priceLink}`)


            } catch (e) {
                const errorMessage = e.message;
                const errorDisplayMessage = errorMessage.toString().replace("Firebase: ", "");
                toast.error(errorDisplayMessage);
                setIsLoading(false);
            }
        } else {
            toast.error("Passwords don't match");
        }
    }

    const validatePasswordMatches = () => {
        if (password === reEnteredPassword &&
            password.length > 7
            && reEnteredPassword.length > 7) {
            setPasswordErrorMessage("");
            return true;
        } else {
            setPasswordErrorMessage("* Please Ensure Your Password's Match & Are Longer Than 7 Characters");
            toast.error("Please Ensure Your Password's Match & Are Longer Than 7 Characters");
            setIsLoading(false);
            return false;
        }
    }
    return (
        <div className="col-12 col-md-6 mx-auto align-items-center">
            <ScrollToTop/>
            <div className={"col-10 mx-auto card bg-white border-white align-items-center justify-content-center"}>
                <div className={"mx-auto align-items-center justify-content-center"}>
                    <CharacterSelector className={"col-12"}
                                       hairColour={hairColour}
                                       setHairColour={setHairColour}
                                       skinTone={skinTone}
                                       setSkinTone={setSkinTone}
                                       hairStyle={hairStyle}
                                       setHairStyle={setHairStyle}
                                       characterName={characterName}
                                       setCharacterName={setCharacterName}
                                       headerMessage={"Create Your Character"}
                                       textColour={'text-navy'}
                    />
                </div>

                <div className={"col-12 mx-auto align-items-center justify-content-center bg-white"}>
                    <div className={"mx-auto align-items-center justify-content-center"}>
                        <TrialSignup email={email}
                                     setEmail={setEmail}
                                     password={password}
                                     setPassword={setPassword}
                                     reEnteredPassword={reEnteredPassword}
                                     setReEnteredPassword={setReEnteredPassword}
                                     headerMessage={"Register"}
                        />
                    </div>
                </div>
                <div><p className={"text-danger text-center"}>{characterNameErrorMessage}</p></div>
                <div><p className={"text-danger text-center"}>{passwordErrorMessage}</p></div>

                <div className={"col-12 mx-auto text-center"}>
                    {
                        isLoading === true ?
                            (
                                <button
                                    className="col-6 oswald-font btn btn-lg btn-warning text-bold rounded-3  rounded-pill mb-3"
                                    onClick={onSubmit}>
                                    LOADING ...
                                </button>
                            )
                            :
                            (
                                <button
                                    className="col-6 oswald-font btn btn-lg btn-primary text-bold rounded-3  rounded-pill mb-3"
                                    onClick={onSubmit}>
                                    START READING
                                </button>
                            )
                    }
                </div>

                <ToastContainer
                    className="toast-position"
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"/>
            </div>
        </div>

    );
};
export default CheckoutTrial;