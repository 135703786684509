import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const LibraryPagination = ({ books, itemsPerPage, currentPage, setCurrentPage }) => {
    const pageCount = Math.ceil(books.length / itemsPerPage);
    let items = [];

    // Previous button
    items.push(
        <Pagination.Prev
            key="prev"
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}  // Disable if on the first page
        />
    );

    // Page number buttons
    for (let number = 1; number <= pageCount; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => setCurrentPage(number)}
            >
                {number}
            </Pagination.Item>
        );
    }

    // Next button
    items.push(
        <Pagination.Next
            key="next"
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount))}
            disabled={currentPage === pageCount}  // Disable if on the last page
        />
    );

    return <Pagination>{items}</Pagination>;
};

export default LibraryPagination;