import React from 'react';
import Slider from "react-slick";
import {DESKTOP_PRODUCT_REVIEW_SETTINGS, MOBILE_PRODUCT_REVIEW_SETTINGS} from "../utils/SliderSettingsUtils.js";
import ProductReviewCard from "./ProductReviewCard";
import {
    REVIEW_FIVE_DESCRIPTION, REVIEW_FIVE_REVIEWER_DETAILS,
    REVIEW_FOUR_DESCRIPTION, REVIEW_FOUR_REVIEWER_DETAILS,
    REVIEW_ONE_DESCRIPTION,
    REVIEW_ONE_REVIEWER_DETAILS, REVIEW_THREE_DESCRIPTION, REVIEW_THREE_REVIEWER_DETAILS,
    REVIEW_TWO_DESCRIPTION,
    REVIEW_TWO_REVIEWER_DETAILS
} from "../utils/ReviewDescriptionsUtils.js";

const reviewStarImg = "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";

const ProductReviewCardsList = () => {
    return (
        <div>
            {/*Text Banner - See What Everyone Has To Say*/}
            <div className="feature-div-container banner-text ne">
                <h3 className={"text-white"}>See What Everyone Has To Say</h3>
            </div>
            
            {/*Review Sliders*/}
            <div className={"review-slider mx-auto col-12 bg-green-yellow-gradient pt-5 pb-5 margin-right-20"}>
                <link rel="stylesheet" href={reviewStarImg}/>
                
                {/*    /!*Desktop Section - Product Reviews*!/*/}
                <div className={"d-none d-md-block "}>
                    <Slider {...DESKTOP_PRODUCT_REVIEW_SETTINGS}>
                        <ProductReviewCard cardTitle={REVIEW_ONE_DESCRIPTION}
                                           reviewerDetails={REVIEW_ONE_REVIEWER_DETAILS}/>

                        <ProductReviewCard cardTitle={REVIEW_TWO_DESCRIPTION}
                                           reviewerDetails={REVIEW_TWO_REVIEWER_DETAILS}/>

                        <ProductReviewCard cardTitle={REVIEW_THREE_DESCRIPTION}
                                           reviewerDetails={REVIEW_THREE_REVIEWER_DETAILS}/>
                    </Slider>
                </div>


                {/*    /!*Mobile Section - Product Reviews*!/*/}
                <div className={"d-md-none "}>
                    <Slider {...MOBILE_PRODUCT_REVIEW_SETTINGS}>
                        <ProductReviewCard cardTitle={REVIEW_ONE_DESCRIPTION}
                                           reviewerDetails={REVIEW_ONE_REVIEWER_DETAILS}/>

                        <ProductReviewCard cardTitle={REVIEW_TWO_DESCRIPTION}
                                           reviewerDetails={REVIEW_TWO_REVIEWER_DETAILS}/>

                        <ProductReviewCard cardTitle={REVIEW_THREE_DESCRIPTION}
                                           reviewerDetails={REVIEW_THREE_REVIEWER_DETAILS}/>
                    </Slider>
                </div>
            </div>

        </div>
    );
};

export default ProductReviewCardsList;