import { createContext } from 'react';

export class TelemetryService {
    constructor(provider) {
      this.provider = provider;
    }
  
    trackEvent(event, properties) {
      this.provider.trackEvent(event, properties);
    }
  
    trackPageView(name, url) {
      this.provider.trackPageView(name, url);
    }
  
    trackException(exception) {
      this.provider.trackException(exception);
    }

    trackMetric(name, value) {
      this.provider.trackMetric({ name, average: value });
    }

    // set auth context
    setAuthenticatedUserContext(userId) {
      this.provider.setUserContext(userId);
    }
    
  }

  export const TelemetryContext = createContext();