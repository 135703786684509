import React, { useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import BookReaderErrorPage from "../pages/error/BookReaderErrorPage";
import PostSaleBookSlider from "../components/PostSaleBookSlider";


const BookReader = () => {
    const location = useLocation();
    const {state} = location;
    const [books, setBooks] = useState([]);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = process.env.REACT_APP_B_KEND + '/book-reader';

                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(state),
                }).then(response => response.json())
                    .then(data => data);

                const data = await response;                
                setBooks(data);

                // Update component state with response data
                setResponse(data);
                setError(null);
            } catch (error) {
                // Handle error
                setError(error.message);
                setResponse(null);
            }
        };

        fetchData();
    }, []);
    
    return (
        <div>
            {!response && !error && <div><h1 className={'text-center'}>Loading ......</h1></div>}
            {response && <div>
                <div>
                    <PostSaleBookSlider books={books}/>
                </div></div>}
            {error && <div> <BookReaderErrorPage errorMessage={""}/> </div>}
        </div>
    );
};

export default BookReader;

