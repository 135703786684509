
const REVIEW_ONE_REVIEWER_DETAILS = "Mariam, Carlton Vic";
const REVIEW_ONE_DESCRIPTION = "As a parent I definitely love the concept. It's user friendly. I love that kid can use it without my help..";

const REVIEW_TWO_REVIEWER_DETAILS = "Sophie, North Melbourne Vic";
const REVIEW_TWO_DESCRIPTION = "Love this, we got one too.";

const REVIEW_THREE_REVIEWER_DETAILS = "Omar, Truganina Vic";
const REVIEW_THREE_DESCRIPTION = "Before using Our Storytime my son never used to read. Now he can't get enough.";

const REVIEW_FOUR_REVIEWER_DETAILS = "Sunny, Sunshine Vic";
const REVIEW_FOUR_DESCRIPTION = "The kids absolutely love this";
const REVIEW_FIVE_REVIEWER_DETAILS = "Hannah, Brunswick Vic";
const REVIEW_FIVE_DESCRIPTION = "Lovely stories for putting the kids to bed!";

export {REVIEW_ONE_REVIEWER_DETAILS,REVIEW_ONE_DESCRIPTION, 
    REVIEW_TWO_REVIEWER_DETAILS, REVIEW_TWO_DESCRIPTION,
    REVIEW_THREE_REVIEWER_DETAILS, REVIEW_THREE_DESCRIPTION,
    REVIEW_FOUR_REVIEWER_DETAILS, REVIEW_FOUR_DESCRIPTION,
    REVIEW_FIVE_REVIEWER_DETAILS, REVIEW_FIVE_DESCRIPTION
}
