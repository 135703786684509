import React from 'react';

const ProductReviewCard = (props) => {
    const {
        cardTitle, reviewerDetails
    } = props;
    
    
    return (
        <div>
            <div className=" card review-card">
                <div className="card-body">
                    <h5 className="card-title">“{cardTitle}”</h5>
                    <h5>
                        <span className="fa fa-star checked"></span>
                        <span className="fa fa-star checked"></span>
                        <span className="fa fa-star checked"></span>
                        <span className="fa fa-star checked"></span>
                        <span className="fa fa-star checked"></span>
                    </h5>
                    <h5 className="card-text">{reviewerDetails}</h5>
                </div>
            </div>
        </div>
    );
};

export default ProductReviewCard;