import React from 'react';
import {usePageViewTracker} from "../../services/TelemetryHooks";


function TermsOfService() {
    usePageViewTracker('Terms of Service');

    document.body.style.backgroundImage = 'url("https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/navy bg for GBCD.png")';

    return (
        <div>
            <h1 className={"text-center mb-5"}>Our Storytime Terms of Service</h1>
            <div className={"col-10 mx-auto justify-content-center align-items-center bg-white p-5  rounded-lg"}
                 style={{borderRadius: '30px'}}>
                <div className="text-center mb-5">
                    <p>This agreement governs your use of the Our Storytime online reading platform available at
                        www.ourstorytime.com and ourstorytime.com (the "Platform"). By accessing or using the Platform,
                        you agree to be bound by these Terms of Service. If you do not agree to these terms, please do
                        not use the Platform. </p>
                </div>


                <div className="text-center">
                    <h3>
                        Definitions
                    </h3>
                </div>
                <div className="text-center mb-5">
                    <p>
                        "Our Storytime" refers to Our Storytime Pty Ltd, with its registered office at Ground floor 470
                        St Kilda Road Melbourne VIC 3004, Australia, ABN 91 666 584 096.
                        "Platform" refers to the Our Storytime website available at www.ourstorytime.com and
                        ourstorytime.com. </p>
                </div>


                <div className="text-center">
                    <h3>
                        Subscription and Services
                    </h3>
                </div>
                <div className="text-center mb-5">
                    <p>
                        Our Storytime offers a subscription product that allows customers to access and read
                        customizable stories on the Platform for a fee. Our Storytime also provides an option for
                        customers to print the stories for personal use for a fee.
                    </p>
                </div>


                <div className="text-center">
                    <h3>
                        Intellectual Property
                    </h3>
                </div>
                <div className="text-center mb-5">
                    <p>
                        All digital assets, logos, designs, and the code base on the Platform are the exclusive property
                        of Our Storytime. Users are granted a limited, non-exclusive, and non-transferable license to
                        use the Platform and its content solely for personal, non-commercial purposes.
                    </p>
                </div>


                <div className="text-center">
                    <h3>
                        Termination of Subscriptions
                    </h3>
                </div>
                <div className="text-center">
                    <p>
                        Our Storytime reserves the right to terminate subscriptions at its discretion.
                        In the event of termination, users will no longer have access to the subscription services, and
                        refunds will be issued subject to the nature of the termination and at the discretion of Our
                        Storytime.
                    </p>
                </div>


                <div className="text-center">
                    <h3>
                        User Responsibilities
                    </h3>
                </div>
                <div className="text-center">
                    <p>
                        Users are responsible for maintaining the confidentiality of their account information and for
                        all activities that occur under their account. Users agree not to use the Platform for any
                        unlawful or unauthorized purposes. </p>
                </div>

                <div className="text-center">
                    <h3>
                        Limitation of Liability
                    </h3>
                </div>
                <div className="text-center">
                    <p>
                        Our Storytime is not liable for any direct, indirect, incidental, special, consequential, or
                        punitive damages arising out of or in connection with the use of the Platform.
                    </p>
                </div>


                <div className="text-center">
                    <h3>
                        Enforceability
                    </h3>
                </div>
                <div className="text-center">
                    <p>
                        These Terms of Service are governed by the laws of Australia. Users agree to submit to the
                        exclusive jurisdiction of the courts of Australia. These Terms of Service are enforceable
                        globally, and users agree to comply with all local laws regarding their use of the
                        Platform. </p>
                </div>

                <div className="text-center">
                    <h3>
                        Modification of Terms
                    </h3>
                </div>
                <div className="text-center">
                    <p>
                        Our Storytime reserves the right to modify or update these Terms of Service at any time. Users
                        will be notified of any changes, and continued use of the Platform constitutes acceptance of the
                        modified terms. </p>
                </div>


                <div className="text-center">
                    <h3>
                        Contact Information
                    </h3>
                </div>
                <div className="text-center">
                    <p>
                        For any inquiries regarding these Terms of Service, please contact Our Storytime at
                        sales@ourstorytime.com.
                    </p>
                    <p>
                        By using the Our Storytime Platform, you acknowledge that you have read, understood, and agree
                        to be bound by these Terms of Service.
                    </p>
                </div>


            </div>
            <div className={"mb-5"}></div>

        </div>
    );
}

export default TermsOfService;
