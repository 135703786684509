import React, {useState, useRef} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../style/cardslider.css'
import SaleItem from "./SaleItem";
import {PRODUCT_OFFERS_LIST} from "../utils/ProductOffersUtils";

const ProductOffersSlider = () => {
    
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef();
    
    const goToSlide = (index) => {
        setCurrentSlide(index);
    };

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1.1,
        slidesToScroll: 1,
        // arrows: true,
        prevArrow: <></>, // Empty React fragment to remove previous arrow
        nextArrow: <></>, // Empty React fragment to remove next arrow
        beforeChange: (_, nextSlide) => setCurrentSlide(nextSlide),
    };

    return (
            <div className="col-10 mx-auto carousel-container">
                <Slider ref={sliderRef} {...settings}>
                    {PRODUCT_OFFERS_LIST.map((slide) => (
                        <div key={slide.id} >
                                <SaleItem
                                          heading={slide.heading}
                                          text={slide.text}
                                          imageUrl={slide.image}
                                          promoText={slide.promoText}
                                          price={slide.price}
                                          itemOne={slide.itemOne}
                                          itemTwo={slide.itemTwo}
                                          itemThree={slide.itemThree}
                                          itemList={slide.itemList}
                                          bannerColor={slide.bannerColor}
                                          textColor={slide.textColor}
                                          buttonText={slide.buttonText}
                                          oldPrice={slide.oldPrice}
                                          priceTextColour={slide.priceTextColour}
                                          discountBackgroundColour={slide.discountBackgroundColour}
                                          discountTextColour={slide.discountTextColour}
                                          discountText={slide.discountText}
                                          linkLocation={slide.linkLocation}
                                          imageList={slide.imageList}


                                />
                        </div>
                    ))}
                </Slider>

            <div className="slide-indicators mt-3">
                {PRODUCT_OFFERS_LIST.map((_, index) => (
                    <div
                        key={index}
                        className={`slide-indicator ${index === currentSlide ? 'active' : ''}`}
                        onClick={() => goToSlide(index)}
                    ></div>
                ))}
            </div>
        </div>
    );
};

export default ProductOffersSlider;
