import React from 'react'
import Confetti from "react-confetti";
import {Link} from "react-router-dom";

const BookConfetti = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    return (
        <div>
            <Link to={"/signed-in"}>
                <button className="end-button book-box col-md-2 col-4 btn btn-lg ">
                    HOME
                </button>
            </Link>
            <div className={"confetti-depth"}>
                <Confetti
                    width={width}
                    height={height}
                    numberOfPieces={1000}
                    recycle={false}
                    gravity={0.08}
                />
            </div>
        </div>
    )
};

export default BookConfetti;
