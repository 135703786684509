import React from "react";

const image1 = "https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/school_cover.png";
const image2 = "https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/birthday_cover.png";
const image3 = "https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/zoo_cover.png";

const BookSelector = () => {
    document.body.style.backgroundImage = 'url("https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/navy bg for GBCD.png")';

    return (
        <div className={"col-10"}>
            <div>
                <div className={"book-selector-heading"}>
                    <h2 className={"logged-in-title"}>1. Select Your Stories</h2>
                    <h5>Please Select ONE More Story to continue</h5>
                </div>
                <div className={"col-10 login-book-div"}>
                    <div className={"col-10 col-lg-4 login-book-read"}>
                        <div className={"home-read-button"}>
                            <img className={"login-book book-cover"} src={image1} alt={"book cover"}/>
                        </div>
                        <div className={"home-read-button"}>
                            <label className={'col-2'}>
                                <input
                                    type="radio"
                                    value="pale"
                                    className={'input-big'}
                                />
                            </label>
                        </div>
                        <div className={"home-read-button"}>
                            <p className={"product-text"}>
                                A taste of the magic of Our Storytime, select from one of our Classic digital stories
                                and let us create a customised story featuring your Avatar.
                                AGES 4-6

                            </p>
                        </div>
                    </div>

                    <div className={"col-12 col-lg-4 login-book-read"}>
                        <div className={"home-read-button"}>
                            <img className={"login-book book-cover"} src={image2} alt={"book cover"}/>
                        </div>
                        <div className={"home-read-button"}>
                            <label className={'col-2'}>
                                <input
                                    type="radio"
                                    value="pale"
                                    className={'input-big'}
                                />
                            </label></div>
                        <div className={"home-read-button"}>

                            <p className={"product-text"}>
                                A taste of the magic of Our Storytime, select from one of our Classic digital stories
                                and let us create a customised story featuring your Avatar.
                                AGES 4-6 </p>
                        </div>
                    </div>

                    <div className={"col-12 col-lg-4  login-book-read"}>
                        <div className={"home-read-button"}>
                            <img className={"login-book book-cover"} src={image3} alt={"book cover"}/>
                        </div>
                        <div className={"home-read-button"}>
                            <label className={'col-2'}>
                                <input
                                    type="radio"
                                    value="pale"
                                    className={'input-big'}
                                />
                            </label></div>
                        <div className={"home-read-button"}>
                            <p className={"product-text"}>
                                A taste of the magic of Our Storytime, select from one of our Classic digital stories
                                and let us create a customised story featuring your Avatar.
                                AGES 4-6 </p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default BookSelector;