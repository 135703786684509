import Hotjar from '@hotjar/browser';
import { TelemetryService } from './services/TelemetryService';
import ApplicationInsightsProvider from './services/telemetryProviders/ApplicationInsightsProvider';
import ConsoleTelemetryProvider from './services/telemetryProviders/ConsoleTelemetryProvider';

export const setupHotjar = () => {
    const siteId = Number(process.env.REACT_APP_HOTJAR_SITE_ID);
    const hotjarVersion = 6;

    if (!isNaN(siteId)) {
        Hotjar.init(siteId, hotjarVersion);
    }
};

export const setupTelemetry = () => {

    const connectionString = process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING;
    const telemetryProvider = connectionString
      ? new ApplicationInsightsProvider(connectionString)
      : new ConsoleTelemetryProvider();
    
    const telemetryService = new TelemetryService(telemetryProvider);

    return telemetryService;
};