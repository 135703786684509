import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../style/home.css"
import "../style/main.css"
import { Button } from "reactstrap";
import { UserAuth } from "../components/AuthContext";
import { usePageViewTracker, useEventTracker } from "../services/TelemetryHooks";
import Book from "../components/Book";
import LibraryPagination from './SignedIn/components/LibraryPagination';
import ScrollToTop from "../components/ScrollToTop";


const SignedIn = () => {
    usePageViewTracker('Signed In');
    const trackEvent = useEventTracker();

    document.body.style.backgroundImage = 'url("https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/navy bg for GBCD.png")';
    const { user, logout } = UserAuth();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 9;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;





    const schoolBookImg = "https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/school_cover.png";
    const birthdayBookImg = "https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/birthday_cover.png";
    const zooBookImg = "https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/zoo_cover.png";
    const avatarChangeBtnImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/icons/update_avatar_button.png";
    const hardCoverBtnImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/icons/hardcover_button.png";
    const logoutBtnImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/icons/logout_button.png";
    const readerDifficultyBtnImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/icons/reader_difficulty_button.png";

    //covers
    const farmBookCover = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/banyard_brunch_cover.png";
    const carBookCover = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/car_book_cover.png";
    const juneBookCoverImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/june_release.png";
    const julyBookCoverImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/july_release.png";
    const augustBookCoverImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/august_release.png";
    const septemberBookCoverImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/september_release.png";
    const octoberBookCoverImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/october_release.png";
    const novemberBookCoverImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/november_release.png";
    const decemberBookCoverImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/december_release.png";


    const [hairColour, setHairColour] = useState("black");
    const [skinTone, setSkinTone] = useState("fair");
    const [hairStyle, setHairStyle] = useState("buzz");
    const [characterName, setCharacterName] = useState("");
    const [promoCode, setPromoCode] = useState("");
    const [zooBookCode, setZooBookCode] = useState("");
    const [carBookCode, setCarBookCode] = useState("");
    const [farmBookCode, setFarmBookCode] = useState("");
    const [testBookCode, setTestBookCode] = useState("");


    const [schoolBookCode, setSchoolBookCode] = useState("");
    const [birthdayBookCode, setBirthdayBookCode] = useState("");

    const data = {
        hairColour,
        skinTone,
        hairStyle,
        characterName,
        promoCode,
        birthdayBookCode,
        schoolBookCode,
        zooBookCode,
        carBookCode,
        farmBookCode
    };



    const postPurchase = async () => {
        try {
            const url = process.env.REACT_APP_B_KEND + '/osthomepage';

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "email": user.email
                }),
            }).then(response => response.json())
                .then(data => data);

            const data = await response;
            setHairStyle(data.hairStyle);
            setHairColour(data.hairColour);
            setSkinTone(data.skinTone);
            setCharacterName(data.characterName);
            setBirthdayBookCode(response.birthdayBook);
            setZooBookCode(response.zooBook);
            setSchoolBookCode(response.schoolBook);
            setCarBookCode(response.carBook);
            setFarmBookCode(response.farmBook);
            setTestBookCode(response.farmBook);
        } catch (error) {
            // Handle error
        }
    };


    useEffect(() => {

        if (user !== undefined && user.email !== undefined) {
            postPurchase();
        }
    }, [user]);

    const handleLogout = async () => {
        try {
            trackEvent('Log Out', {
                email: user.email
            });

            await logout();
            navigate('/');
        } catch (e) {
            console.log(e.message())
        }
    }


    const updateAvatar = () => {
        trackEvent('Update Avatar');
        navigate('/store-character');
    }

    const purchaseHardCover = () => {
        trackEvent('Purchase Hard Cover');
        navigate('/buy-hard-cover-book-form');
    }

    const unlockPass = async () => {
        trackEvent('Unlock Books');
        navigate('/upgrade-plan-form');
    }


    const readBook = async (bookCode) => {

        setPromoCode(bookCode);
        postPurchase();

        if (bookCode !== "" && promoCode === bookCode) {
            console.log("book code:" + bookCode);
            navigate("/book-reader", { state: data })
        }
    }


    const books = [
        {
            image: carBookCover,
            bookCode: carBookCode,
            testBookCode: testBookCode,
            isReleased: true,
            readBookFunc: readBook,
        },

        {
            image: farmBookCover,
            bookCode: farmBookCode,
            testBookCode: testBookCode,
            isReleased: true,
            readBookFunc: readBook,
        },
        {
            image: birthdayBookImg,
            bookCode: birthdayBookCode,
            testBookCode: testBookCode,
            isReleased: true,
            readBookFunc: readBook,
        },
        {
            image: schoolBookImg,
            bookCode: schoolBookCode,
            testBookCode: testBookCode,
            isReleased: true,
            readBookFunc: readBook,
        },
        {
            image: zooBookImg,
            bookCode: zooBookCode,
            testBookCode: testBookCode,
            isReleased: true,
            readBookFunc: readBook,
        },
        
        {
            image: juneBookCoverImg,
            bookCode: "",
            testBookCode: testBookCode,
            isReleased: false,
            readBookFunc: readBook,
        },
        {
            image: julyBookCoverImg,
            bookCode: "",
            testBookCode: testBookCode,
            isReleased: false,
            readBookFunc: readBook,
        },
        {
            image: augustBookCoverImg,
            bookCode: "",
            testBookCode: testBookCode,
            isReleased: false,
            readBookFunc: readBook,
        },
        {
            image: septemberBookCoverImg,
            bookCode: "",
            testBookCode: testBookCode,
            isReleased: false,
            readBookFunc: readBook,
        },
        {
            image: octoberBookCoverImg,
            bookCode: "",
            testBookCode: testBookCode,
            isReleased: false,
            readBookFunc: readBook,
        },
        {
            image: novemberBookCoverImg,
            bookCode: "",
            testBookCode: testBookCode,
            isReleased: false,
            readBookFunc: readBook,
        },
        {
            image: decemberBookCoverImg,
            bookCode: "",
            testBookCode: testBookCode,
            isReleased: false,
            readBookFunc: readBook,
        },
    ].sort((a, b) => Boolean(b.bookCode) - Boolean(a.bookCode));


    return (
        <div className={"col-11 mx-auto "}>
            <ScrollToTop/>

            {/*Library*/}
            <div className={"col-12 home-box pb-3"}>
                <h1 className="bg-green-yellow-gradient agbalumo-font text-navy-blue small-rounded-top p-3">
                    {characterName ? `${characterName}'s Library` : 'My Library'}
                </h1>

                {/*Books*/}
                <div className="book-container">
                    {books.slice(startIndex, endIndex).map((book, index) => (
                        <Book
                            key={index}
                            image={book.image}
                            bookCode={book.bookCode}
                            testBookCode={book.testBookCode}
                            isReleased={book.isReleased}
                            readBookFunc={book.readBookFunc}
                        />
                    ))}
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <LibraryPagination
                        books={books}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
            </div>


            {/*Settings*/
            }
            <div className={" col-12 justify-content-between  mb-3"}>
                <div className="row justify-content-between">

                    <Button className={"col-md-3  col-12  img-button mt-3"}>
                        <img src={avatarChangeBtnImg} width={"100%"} onClick={updateAvatar}
                            alt={"change avatar button"} />
                    </Button>

                    <Button className={"col-md-3  col-12  btn img-button mt-3"}>
                        <img src={hardCoverBtnImg} width={"100%"} onClick={purchaseHardCover}
                            alt={"purchase hard cover button"} />
                    </Button>

                    <Button className={"col-md-3  col-12   img-button mt-3"}>
                        <img src={logoutBtnImg} width={"100%"} onClick={handleLogout} alt={"logout button"} />
                    </Button>

                    <Button className={"col-md-3  col-12  - img-button mt-3"}>
                        <img src={readerDifficultyBtnImg} width={"100%"} alt={"reader difficulty button"} />
                    </Button>
                </div>
            </div>


            {/*Coming Soon*/
            }
            <div className={"col-12 home-box bg-green-yellow-gradient mb-5"}>
                <h2 className={"agbalumo-font text-navy-blue small-rounded-top pb-3"}>Coming Soon</h2>
                <h3 className={"oswald-font text-center mx-auto col-10 mt-3 mb-5"}>Enjoy an engaging story
                    of {characterName}'s first football match. Experience the excitment of making new friends, bonding
                    as a team and great reward for your efforts.</h3>

                <div className={"d-none d-lg-block"}>
                    <img className={"rounded"} width="100%"
                        src={"https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/promo_amazing_football_cover.png"}
                        alt={"coming soon"} />
                </div>

                <div className={"d-block d-md-block d-lg-none"}>
                    <img className={"rounded"} width="100%"
                        src={"https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/m_promo_amazing_football_cover.png"}
                        alt={"coming soon"} />
                </div>
                {/*<SoundButton/>*/}
            </div>
        </div>
    )
        ;
};

export default SignedIn;
