import { useEffect } from 'react';
import { useContext } from 'react';
import { TelemetryContext } from './TelemetryService';

export const usePageViewTracker = (pageName) => {
  const telemetryService = useContext(TelemetryContext);
  useEffect(() => {
    telemetryService.trackPageView(pageName, window.location.href);
  }, [pageName]);
};

export const useEventTracker = () => {
  const telemetryService = useContext(TelemetryContext);

  const trackEvent = (eventName, eventData) => {
    telemetryService.trackEvent(eventName, eventData);
  };

  return trackEvent;
};