import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserAuth} from "./AuthContext";

const PostSaleTrialSignedIn = () => {
    const {user} = UserAuth();
    const navigate = useNavigate();
    const [purchaseCode, setPurchaseCode] = useState('');
    
    useEffect(() => {
        const url = window.location.href;
        const urlObj = new URL(url);
        const searchParams = new URLSearchParams(urlObj.search);
        const trialCode = searchParams.get('sessionid');
        setPurchaseCode(trialCode);


        const postPurchase = async (trialCode) => {
            try {
                const url = process.env.REACT_APP_B_KEND + '/checkout';

                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "bookName": "book",
                        "purchaseCode": trialCode,
                        "email": user.email

                    }),
                }).then(response => response.json())
                    .then(data => data);

                const data = await response;
            } catch (error) {
                // Handle error
            }
        };
        if (user !== undefined && user.email !== undefined) {
            postPurchase(trialCode);
            navigate('/signed-in');
        }
    }, [user]);
    
    return (
        <div>
            <h1 className={"text-center"}>Loading ....</h1>
            <h3 className={"text-center text-white"}>Please Don't Refresh Or Press Back</h3>
        </div>
    );
};

export default PostSaleTrialSignedIn;
