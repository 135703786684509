import React, {useState, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../style/cardslider.css'

const CardSlider = () => {
    document.body.style.backgroundImage = 'url("https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/navy bg for GBCD.png")';
    const image1 = "https://oststorage.blob.core.windows.net/ostpstrblb/app/guide_1.gif";
    const image2 = "https://oststorage.blob.core.windows.net/ostpstrblb/app/guide_2.gif";
    const image3 = "https://oststorage.blob.core.windows.net/ostpstrblb/app/guide_3.gif";
    const image4 = "https://oststorage.blob.core.windows.net/ostpstrblb/app/guide_4.gif";

    const gifRef = useRef(null);

    const handleGifLoad = () => {
        if (gifRef.current) {
            gifRef.current.addEventListener('animationend', handleAnimationEnd);
        }
    };

    const handleAnimationEnd = () => {
        if (gifRef.current) {
            gifRef.current.removeEventListener('animationend', handleAnimationEnd);
            gifRef.current.style.pointerEvents = 'none';
        }
    };

    const slidesData = [
        {
            id: 1,
            title: 'Course 1',
            image: image1,
            description: 'Description for Course 1'
        },
        {
            id: 2,
            title: 'Course 2',
            image: image2,
            description: 'Description for Course 2'
        },
        {
            id: 3,
            title: 'Course 3',
            image: image3,
            description: 'Description for Course 3'
        },
        {
            id: 4,
            title: 'Course 4',
            image: image4,
            description: 'Description for Course 4'
        }
    ];

    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef();
    const navigate = useNavigate();


    const nextSlide = () => {
        if (currentSlide === 3) {
            navigate('/character-selector'); // Redirect to another page on the last slide
        } else {
            sliderRef.current.slickNext();

        }
    };

    const goToSlide = (index) => {
        setCurrentSlide(index);
        sliderRef.current.slickGoTo(index);
    };

    const settings = {
        infinite: false,
        speed: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <></>, // Empty React fragment to remove previous arrow
        nextArrow: <></>, // Empty React fragment to remove next arrow
        beforeChange: (_, nextSlide) => setCurrentSlide(nextSlide),
    };

    return (
        <div className="col-md-12 cardslider-container">
            {/*<h1>Book Creation Process</h1>*/}
            <div className="col-md-6 col-10 card-slider-card">
                <Slider ref={sliderRef} {...settings}>
                    {slidesData.map((slide) => (
                        <div key={slide.id} className="card">
                            {/*<h3>{slide.title}</h3>*/}
                            <img src={slide.image} alt={`Course ${slide.id}`}
                                 ref={gifRef}
                                 onLoad={handleGifLoad}
                            />
                            {/*<p>{slide.description}</p>*/}
                        </div>
                    ))}
                </Slider>
            </div>

            <div className="slide-indicators">
                {slidesData.map((_, index) => (
                    <div
                        key={index}
                        className={`slide-indicator ${index === currentSlide ? 'active' : ''}`}
                        onClick={() => goToSlide(index)}
                    ></div>
                ))}
            </div>
            <div>
                {currentSlide !== slidesData.length - 1 ? (
                    <button className="col-md-3 col-6 btn btn-lg cardslider-button" onClick={nextSlide}>
                        Next
                    </button>
                ) : <button className="col-md-3 col-6 btn btn-lg cardslider-button" onClick={nextSlide}>
                    Continue
                </button>}
            </div>
        </div>
    );
};

export default CardSlider;
