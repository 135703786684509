import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserAuth } from "../components/AuthContext";
import { usePageViewTracker } from "../services/TelemetryHooks";
import { TelemetryContext } from '../services/TelemetryService';


const Login = () => {
    usePageViewTracker('Login');
    const telemetryService = useContext(TelemetryContext);

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { signIn } = UserAuth();
    document.body.style.backgroundImage = 'url("https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/navy bg for GBCD.png")';

    const onLogin = async (e) => {
        toast.info("Signing In", { autoClose: 1000 });
        e.preventDefault();
        setError('');
    
        try {
            await signIn(email, password);
    
            telemetryService.setAuthenticatedUserContext(email);
            telemetryService.trackEvent("User Login", {
                email: email
            });
    
            navigate('/signed-in');
        } catch (error) {
            const errorDisplayMessage = getAuthErrorMessage(error.code) || 'An unexpected error occurred. Please try again.';
            setError(errorDisplayMessage);
            toast.error(errorDisplayMessage);
    
            // Optionally, you could decide to log this event as a specific error
            telemetryService.trackEvent("Login Failure", {
                email: email,
                error: error.code
            });
        }
    }

    return (
        <div className={"col-md-4 col-10 mx-auto"}>
            <div className={"table-div"}>
                <table className={"col-12 align-items-center justify-content-center mx-auto"}>
                    <thead>
                        <tr>
                            <th className={"login-column mx-auto align-items-center justify-content-center"}>
                                <div className={"login-box align-items-center justify-content-center"}>
                                    <div className={"login-text-box align-items-center justify-content-center"}>
                                        <h2 className="login-header">Login</h2>
                                    </div>
                                    <form>
                                        <div>
                                            <div>
                                                <label className={"login-text col-12"} htmlFor="email-address">
                                                    EMAIL ADDRESS
                                                </label>
                                            </div>

                                            <input
                                                className={"login-input col-10 col-md-8"}
                                                id="email-address"
                                                name="email"
                                                type="email"
                                                required
                                                placeholder="ENTER EMAIL ADDRESS"
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>

                                        <div>
                                            <div>
                                                <div>
                                                    <label className={"login-text col-12"} htmlFor="password">
                                                        PASSWORD
                                                    </label>
                                                </div>
                                                <input
                                                    className={"login-input col-10 col-md-8"}
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    required
                                                    placeholder="   ENTER PASSWORD"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <button
                                                className={"col-6 btn secondary-button"}
                                                onClick={onLogin}
                                            >
                                                LOGIN
                                            </button>
                                            <div><p className={"text-danger text-center"}>{error}</p></div>
                                        </div>
                                        <div>
                                            <span>Forgot Your Password? </span>
                                            <a href="/forgot-password">Click here</a>
                                        </div>
                                        <br />

                                    </form>
                                </div>

                            </th>
                        </tr>
                    </thead>
                </table>
            </div>

            <ToastContainer
                className="toast-position"
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark" />
        </div>
    )
}

export const getAuthErrorMessage = (errorCode) => {
    switch (errorCode) {
        case 'auth/invalid-email':
            return 'Please enter a valid email address.';
        case 'auth/user-disabled':
            return 'This account has been disabled.';
        case 'auth/user-not-found':
        case 'auth/wrong-password':
            return 'Incorrect email/password. Please try again.';
        default:
            return null;
    }
}

export default Login