import React from "react";
import {useNavigate} from "react-router-dom";
import {Carousel} from 'react-bootstrap';
import "../style/main.css"


const SaleItem = (props) => {
    const {
        heading, text, promoText, price, priceTextColour,
        bannerColor,
        textColor, oldPrice, linkLocation, buttonText,
        discountTextColour, discountBackgroundColour, discountText    } = props;
    const bannerStyle = {
        backgroundColor: bannerColor,
        color: textColor
    };

    const discountStyle = {
        backgroundColor: discountBackgroundColour,
        color: discountTextColour
    }

    const priceStyle = {
        color: priceTextColour
    }
    const navigate = useNavigate();

    function handleSubmit(event) {
        navigate(linkLocation);
        event.preventDefault();
    };

    return (
        <div>
            <div>
                <h3 className={"sale-promo-deal sale-banner-heading"} style={bannerStyle}>{promoText}</h3>
            </div>

            <div className={"card sale-item"}>
                <h3 className={"sale-text-heading "}>{heading} </h3>
                <div className="yellow-line"/>
                <p className={"sale-item-text"}>{text}</p>


                <Carousel interval={null}>
                    {props.imageList.map((imageUrl, index) => (
                        <Carousel.Item key={index}>
                            <img
                                className="d-block w-100 sale-image"
                                src={imageUrl}
                                alt={`Slide ${index}`}
                            />
                        </Carousel.Item>
                    ))}
                </Carousel>


                <div className={"discount-box p-1"} style={discountStyle}>{discountText}</div>

                <div className={"col-12 mt-3"}>
                    {Array.isArray(props.itemList) && props.itemList.map((vava, index) => (
                        <div className={"col-12  same-line-img-container custom-list"}>
                            <img
                                alt="tick"
                                src={"https://oststorage.blob.core.windows.net/ostpstrblb/app/item-tick.png"}
                                className={" tick-image"}/>
                            <h3 key={index} className={"col-11 align-items-center list-item"}>{vava}</h3>
                        </div>
                    ))}
                </div>

                <h2 className={"mt-1"}>
                    <span className={"sale-old-price sale-text-heading"}>{oldPrice} </span> <span
                    className={"sale-text-heading"} style={priceStyle}>{price}</span>
                </h2>
                <button
                    className={"col-6 sale-item-button"}
                    onClick={handleSubmit}>{buttonText}</button>
            </div>
        </div>
    );
};

export default SaleItem;