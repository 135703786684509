import React from "react";
import ProductOffersSlider from "../components/ProductOffersSliderList";
import SaleItem from "../components/SaleItem";
import {PRODUCT_OFFERS_LIST} from "../utils/ProductOffersUtils";
import {usePageViewTracker} from "../services/TelemetryHooks";


const BuyNow = () => {
    usePageViewTracker('Buy Now');

    document.body.style.backgroundImage = 'url("https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/navy bg for GBCD.png")';

    return (
        <div>
            <div>
                <h1 className={"sale-item-title display-1"}>Read your way.</h1>
            </div>
            <div className={"mx-auto medium-text text-gold text-center col-lg-6 col-10 "}>
                <h3 className={"mb-5"}>A growing digital library, now in premium hardcover, so you can bring your stories to life.</h3></div>

            <div className={"row col-10 mx-auto"}>
                {PRODUCT_OFFERS_LIST.map((product) => (
                        <div key={product.id} className="col-4 mx-auto d-none d-sm-block">
                            <SaleItem
                                heading={product.heading}
                                text={product.text}
                                imageUrl={product.image}
                                promoText={product.promoText}
                                price={product.price}
                                itemOne={product.itemOne}
                                itemTwo={product.itemTwo}
                                itemThree={product.itemThree}
                                bannerColor={product.bannerColor}
                                textColor={product.textColor}
                                buttonText={product.buttonText}
                                itemList={product.itemList}
                                oldPrice={product.oldPrice}
                                priceTextColour={product.priceTextColour}
                                discountBackgroundColour={product.discountBackgroundColour}
                                discountTextColour={product.discountTextColour}
                                discountText={product.discountText}
                                linkLocation={product.linkLocation}
                                imageList={product.imageList}
                            />
                        </div>
                    )
                )}
            </div>

            {/*Mobile Section - product slider */}
            <div className={"col-12 mx-auto d-block d-sm-none"}>
                <ProductOffersSlider className={"col-12 mx-auto d-block d-sm-none"}/>
            </div>
        </div>);
}

export default BuyNow