import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserAuth} from "./AuthContext";
import CharacterSelector from "./CharacterSelector";
import ScrollToTop from "./ScrollToTop";


const StoreCharacter = () => {
    document.body.style.backgroundImage = 'url("https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/navy bg for GBCD.png")';

    const {user} = UserAuth();
    const [hairColour, setHairColour] = useState("black");
    const [skinTone, setSkinTone] = useState("fair");
    const [hairStyle, setHairStyle] = useState("buzzhair");
    const [characterName, setCharacterName] = useState("");
    const [error, setError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const url = process.env.REACT_APP_B_KEND + '/avatar';

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(
                    {
                        "email": user.email,
                        "hairStyle": hairStyle,
                        "hairColour": hairColour,
                        "skinTone": skinTone,
                        "characterName": characterName
                    }
                ),
            }).then(response => response.json())
                .then(data => data);

            const data = await response;
            setError(null);
        } catch (error) {
            // Handle error
            setError(error.message);
        }
    };


    function handleSubmit(event) {
        if (characterName != '') {
            fetchData();
            navigate("/signed-in")
            event.preventDefault();
        } else {
            setErrorMessage("Please Enter Your Child's Name");
        }

    };

    const handleSkinToneChange = (event) => {
        setSkinTone(event.target.value)
    };

    const handleCharacterNameChange = (event) => {
        setCharacterName(event.target.value)
    };

    const handleHairColourChange = (event) => {
        setHairColour(event.target.value)
    };

    const handleHairStyleChange = (event) => {
        setHairStyle(event.target.value)
    };


    return (
        <div className={"col-12 col-md-6 mx-auto align-items-center"}>
            <ScrollToTop/>
            <div className={"col-10 mx-auto card bg-white border-white align-items-center justify-content-center"}>
                    <CharacterSelector
                        hairColour={hairColour}
                        setHairColour={setHairColour}
                        skinTone={skinTone}
                        setSkinTone={setSkinTone}
                        hairStyle={hairStyle}
                        setHairStyle={setHairStyle}
                        characterName={characterName}
                        setCharacterName={setCharacterName}
                        headerMessage={"Update Character"}
                        textColour={'text-navy'}
                        secondaryTextColour={"text-navy"}
                        handleSubmit={handleSubmit}
                    />
                <div><h3 className={"text-danger text-center"}>{errorMessage}</h3></div>

                    <button className="col-md-3 col-6 btn btn-lg secondary-button" onClick={handleSubmit}>
                        SAVE
                    </button>

                </div>
        </div>
    );
};
export default StoreCharacter;
            