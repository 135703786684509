import React from 'react';

const ErrorPage = () => {
    return (
        <div className={"error-page-container col-10 mx-auto"}>
            <div className={"col-lg-8 error-page-box"}>
                <div className={"error-page-content"}>
                    <h1 className={"error-page-heading"}>Page Not Found...</h1>
                    <img className={"error-page-image"}
                         src={"https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/error_logo.png"}
                         width={"40%"}
                    alt={"error page"}/>
                    <h5 className={"error-page-text"}>THE PAGE YOU WERE LOOKING FOR DOES NOT EXIST</h5>
                    <p className={"error-page-text"}>Unless you were looking for a page with an animation of our furry
                        friend Owllie. If that’s the case,
                        it definitely exists, and you definitely found it.</p>
                </div>
            </div>

        </div>
    );
};

export default ErrorPage;