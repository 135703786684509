
const HOME_BUTTON_STYLE = {
        position: 'absolute',
        top: '85%',
        left: '10%',
        transform: 'translate(-50%, -50%)',
        textAlign: "center",
        backgroundColor: "rgba(0, 0, 0, 0)",
        width: '70%',
        letterSpacing: "2px",
        zIndex: '10000'
}

const BOOK_PROGRESS_CIRCLE_GRAPH_STYLE ={
        position: 'absolute',
        top: '85%',
        left: '90%',
        transform: 'translate(-50%, -50%)',
        textAlign: "center",
        backgroundColor: "white",
        width: "60px",
        height: "60px",
        letterSpacing: "2px",
        borderRadius: "70px",
        fontFamily: "Gagalin",
        zIndex: '10000'
}

export{HOME_BUTTON_STYLE, BOOK_PROGRESS_CIRCLE_GRAPH_STYLE}