import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';


const CharacterSelector = ({
                               hairColour,
                               setHairColour,
                               skinTone,
                               setSkinTone,
                               hairStyle,
                               setHairStyle,
                               characterName,
                               setCharacterName,
                               headerMessage,
                               textColour,
                               secondaryTextColour,
                               handleSubmit
                           }) => {
    document.body.style.backgroundImage = 'url("https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/navy bg for GBCD.png")';

    const [hairStyleNum, SetHairStyleNum] = useState("1");
    const [hairColourNum, SetHairColourNum] = useState("1");
    const [skinToneNum, setSkinToneNum] = useState("1");
    const [imgPath, setImgPath] = useState("1_1_1.png");
    const [demoCharacterImg, setDemoCharacterImg] = useState("https://oststorage.blob.core.windows.net/ostpstrblb/app/character_selector/" + imgPath);


    const handleSkinToneChange = (e, num) => {
        const newSkinTone = e.target.value;
        setSkinTone(newSkinTone);
        setSkinToneNum(num);
        // Calculate the new image path based on the current changes, not the state
        const newPath = hairStyleNum + "_" + num + "_" + hairColourNum + ".png";
        setImgPath(newPath);
        // Set the new character image path
        setDemoCharacterImg("https://oststorage.blob.core.windows.net/ostpstrblb/app/character_selector/" + newPath);
    }


    const handleHairColourChange = (e, num) => {
        const newHairColour = e.target.value;
        setHairColour(newHairColour);
        SetHairColourNum(num);
        // Calculate the new image path based on the current changes, not the state
        const newPath = hairStyleNum + "_" + skinToneNum + "_" + num + ".png";
        setImgPath(newPath);
        // Set the new character image path
        setDemoCharacterImg("https://oststorage.blob.core.windows.net/ostpstrblb/app/character_selector/" + newPath);
    }


    const handleHairStyleChange = (e, num) => {
        const newHairStyle = e.target.value;
        setHairStyle(newHairStyle);
        SetHairStyleNum(num);
        // Calculate the new image path based on the current changes, not the state
        const newPath = num + "_" + skinToneNum + "_" + hairColourNum + ".png";
        setImgPath(newPath);
        // Set the new character image path
        setDemoCharacterImg("https://oststorage.blob.core.windows.net/ostpstrblb/app/character_selector/" + newPath);
    }

    return (
        <div className={"col-12 text-center "}>
            <h3 className={`col-10 text-center mx-auto login-header mt-2 ${secondaryTextColour}`}>{headerMessage}</h3>
            <div className={'col-12 mx-auto card bg-white border-white'}>
                <form onSubmit={handleSubmit}>
                    <div className={"mb-2"}>
                        <input className="col-10 text-input" type="input" id="input" name="input"
                               placeholder="ENTER YOUR CHARACTER'S NAME" value={characterName}
                               onChange={(e) => setCharacterName(e.target.value)}/>
                    </div>

                    {/*Select Skin Tone*/}
                    <div className={'col-10 mx-auto'}>
                        <h3 className={`login-header ${textColour}`}>Select Skin Tone</h3>
                        <div className={"d-flex justify-content-between"}>
                            <label className={'col-2'}>
                                <img className="select-image"
                                     src="https://oststorage.blob.core.windows.net/ostpstrblb/app/fairskin.png"
                                     alt={"skin tone"}/>
                                <input
                                    type="radio"
                                    value="fair"
                                    className={'input-hidden'}
                                    checked={skinTone === 'fair'}
                                    onChange={(e) => handleSkinToneChange(e, "1")}
                                />
                            </label>
                            <label className={'col-2'}>
                                <img className="select-image"
                                     src="https://oststorage.blob.core.windows.net/ostpstrblb/app/goldenskin.png"
                                     alt={"skin tone"}/>
                                <input
                                    type="radio"
                                    value="golden"
                                    className={'input-hidden'}
                                    checked={skinTone === 'golden'}
                                    onChange={(e) => handleSkinToneChange(e, "2")}
                                />
                            </label>
                            <label className={'col-2'}>
                                <img className="select-image"
                                     src="https://oststorage.blob.core.windows.net/ostpstrblb/app/tanskin.png"
                                     alt={"skin tone"}/>
                                <input
                                    type="radio"
                                    value="tan"
                                    className={'input-hidden'}
                                    checked={skinTone === 'tan'}
                                    onChange={(e) => handleSkinToneChange(e, "3")}
                                />
                            </label>
                            <label className={'col-2'}>
                                <img className="select-image"
                                     src="https://oststorage.blob.core.windows.net/ostpstrblb/app/brownskin.png"
                                     alt={"skin tone"}/>

                                <input
                                    type="radio"
                                    value="brown"
                                    className={'input-hidden'}
                                    checked={skinTone === 'brown'}
                                    onChange={(e) => handleSkinToneChange(e, "4")}
                                />
                            </label>
                            <label className={'col-2'}>
                                <img className="select-image"
                                     src="https://oststorage.blob.core.windows.net/ostpstrblb/app/darkbrownskin.png"
                                     alt={"skin tone"}/>

                                <input
                                    type="radio"
                                    value="darkbrown"
                                    className={'input-hidden'}
                                    checked={skinTone === 'darkbrown'}
                                    onChange={(e) => handleSkinToneChange(e, "5")}
                                />
                            </label>
                        </div>
                    </div>


                    {/*Select hair style*/}
                    <div className={"col-10 mx-auto"}>
                        <h3 className={`login-header ${textColour}`}>Select Hairstyle</h3>
                        <div className={"d-flex justify-content-between"}>
                            <label className={'col-2'}>
                                <img className="select-image"
                                     src="https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/buzz.png"
                                     alt={"hair style"}/>

                                <input
                                    type="radio"
                                    value="buzzhair"
                                    className={'input-hidden'}
                                    checked={hairStyle === 'buzzhair'}
                                    onChange={(e) => handleHairStyleChange(e, "1")}
                                />

                            </label>
                            <label className={'col-2'}>
                                <img className="select-image"
                                     src="https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/afro.png"
                                     alt={"hair style"}/>

                                <input
                                    type="radio"
                                    value="afrohair"
                                    className={'input-hidden'}
                                    checked={hairStyle === 'afrohair'}
                                    onChange={(e) => handleHairStyleChange(e, "2")}
                                />
                            </label>
                            <label className={'col-2'}>
                                <img className="select-image"
                                     src="https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/spikey.png"
                                     alt={"hair style"}/>

                                <input
                                    type="radio"
                                    value="spikeyhair"
                                    className={'input-hidden'}
                                    checked={hairStyle === 'spikeyhair'}
                                    onChange={(e) => handleHairStyleChange(e, "3")}
                                />
                            </label>
                            <label className={'col-2'}>
                                <img className="select-image"
                                     src="https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/long.png"
                                     alt={"hair style"}/>

                                <input
                                    type="radio"
                                    value="longhair"
                                    className={'input-hidden'}
                                    checked={hairStyle === 'longhair'}
                                    onChange={(e) => handleHairStyleChange(e, "4")}
                                />
                            </label>
                            <label className={'col-2'}>
                                <img className="select-image"
                                     src="https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/ponytail.png"
                                     alt={"hair style"}/>

                                <input
                                    type="radio"
                                    value="braidshair"
                                    className={'input-hidden'}
                                    checked={hairStyle === 'braidshair'}
                                    onChange={(e) => handleHairStyleChange(e, "5")}
                                />
                            </label>
                            <label className={'col-2'}>
                                <img className="select-image"
                                     src="https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/short.png"
                                     alt={"hair style"}/>

                                <input
                                    type="radio"
                                    value="shorthair"
                                    className={'input-hidden'}
                                    checked={hairStyle === 'shorthair'}
                                    onChange={(e) => handleHairStyleChange(e, "6")}
                                />
                            </label>
                        </div>
                    </div>

                    {/*Select Hair Colour*/}
                    <div className={"col-10 mx-auto"}>
                        <h3 className={`login-header ${textColour}`}>Select Hair Colour</h3>
                        <div className={"d-flex justify-content-between"}>
                            <label className={'col-2'}>
                                <img className="select-image"
                                     src="https://oststorage.blob.core.windows.net/ostpstrblb/app/blackhair.png"
                                     alt={"hair colour"}/>

                                <input
                                    type="radio"
                                    value="black"
                                    className={'input-hidden'}
                                    checked={hairColour === 'black'}
                                    onChange={(e) => handleHairColourChange(e, "1")}
                                />
                            </label>
                            <label className={'col-2'}>
                                <img className="select-image"
                                     src="https://oststorage.blob.core.windows.net/ostpstrblb/app/brownhair.png"
                                     alt={"hair colour"}/>

                                <input
                                    type="radio"
                                    value="brown"
                                    className={'input-hidden'}
                                    checked={hairColour === 'brown'}
                                    onChange={(e) => handleHairColourChange(e, "2")}
                                />
                            </label>
                            <label className={'col-2'}>
                                <img className="select-image"
                                     src="https://oststorage.blob.core.windows.net/ostpstrblb/app/orangehair.png"
                                     alt={"hair colour"}/>
                                <input
                                    type="radio"
                                    value="orange"
                                    className={'input-hidden'}
                                    checked={hairColour === 'orange'}
                                    onChange={(e) => handleHairColourChange(e, "3")}
                                />
                            </label>
                            <label className={'col-2'}>
                                <img className="select-image"
                                     src="https://oststorage.blob.core.windows.net/ostpstrblb/app/blondehair.png"
                                     alt={"hair colour"}/>

                                <input
                                    type="radio"
                                    value="blonde"
                                    className={'input-hidden'}
                                    checked={hairColour === 'blonde'}
                                    onChange={(e) => handleHairColourChange(e, "4")}
                                />
                            </label>
                            <label className={'col-2'}>
                                <img className="select-image"
                                     src="https://oststorage.blob.core.windows.net/ostpstrblb/app/redhair.png"
                                     alt={"hair colour"}/>

                                <input
                                    type="radio"
                                    value="red"
                                    className={'input-hidden'}
                                    checked={hairColour === 'red'}
                                    onChange={(e) => handleHairColourChange(e, "5")}
                                />
                            </label>
                        </div>
                    </div>
                </form>

                {/*Character Display*/}
                <div>
                    <img src={demoCharacterImg} alt="your character with your settings"
                         className={"col-10 col-md-8 mt-3"} width={"100%"}/>
                </div>
                
                <h3 className={`col-10 mx-auto mt-4 login-header ${textColour}`}>Learn A Language</h3>
                <p className={`${textColour}`}>Choose a second language other than English to learn</p>
                <div className={"col-10 mx-auto text-center"}>
                    <Form.Select aria-label="Second language Selection" className={"text-center login-input"}>
                        {/*<option value="0">English - No Second Language</option>*/}
                        <option value="1">English - Learn Arabic Words</option>
                        <option value="2" disabled>English - Learn Spanish Words</option>
                        <option value="3" disabled>English - Learn Somali Words</option>
                        <option value="4" disabled>English - Learn Urdu Words</option>
                        <option value="9" disabled>English - Learn Italian Words</option>
                        <option value="5" disabled>English - Learn Japanese Words</option>
                        <option value="6" disabled>English - Learn Turkish Words</option>
                        <option value="7" disabled>English - Learn Bengali Words</option>
                        <option value="8" disabled>English - Learn French Words</option>
                    </Form.Select>
                </div>
            </div>
        </div>
    );
};
export default CharacterSelector;
            