import React from "react";
import { Link } from "react-router-dom";
import "../style/home.css"
import "../style/main.css"
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductReviewCardsList from "../components/ProductReviewCardsList";
import { usePageViewTracker, useEventTracker } from "../services/TelemetryHooks";
import ScrollToTop from "../components/ScrollToTop";

const Home = () => {
    usePageViewTracker('Home');
    const trackEvent = useEventTracker();



    const howItWorksOneImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/why_square_1.png"
    const howItWorksTwoImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/why_square_2.png"
    const howItWorksThreeImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/why_square_3.png"
    const threeHardCoverBooksImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/landing_square_three_books.png"
    const hardCoverBirthdayBookImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/landing_square_birthday.png"
    const hardCoverSchoolBookImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/landing_square_school.png"
    const hardCoverZooBookImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/landing_square_zoo.png"


    const handleClick = (eventName) => {
        trackEvent(eventName);
    };


    return (
        <div className={"bg-home"}>
            <ScrollToTop/>
            {/*Section - Main Landing*/}
            <div className="feature-div-container landing-main-div-container mx-auto ">
                <div className={"landing-main-text text-center mx-auto"}>
                    <h1 className={"col-10 agbalumo-font text-center mx-auto"}>Where Your Kids Are The Star Of The Story</h1>
                    <div className={"col-12 mx-auto text-center"}>
                        <Link to={"/checkout-trial"}>
                            <button onClick={handleClick('Show Now')} className="col-8 col-md-4 btn btn-lg secondary-button">
                                CREATE A FREE STORY
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

            {/*Text Banner - Amazing customisable stories*/}
            <div className="feature-div-container banner-text ">
                <h3 className={"text-white"}>What Is Our Storytime?</h3>
            </div>


            {/*Section - Bed Time Stories But Different*/}
            <div className={"col-12 feature-div-container landing-secondary-div-container  text-center"}></div>


            {/*Text Banner - How Does it Work*/}
            <div className="feature-div-container banner-text ">
                <h3 className={"text-white"}>Why Storytimers Love It?</h3>
            </div>


            {/*Section - How It Works Guide*/}
            <div className="multi-item-container">
                <div className={"row col-12 mx-auto text-center"}>

                    <div className={"col-lg-3 col-11 mx-auto mt-3"}>
                        <img className={"full-width-height-image"}
                            src={howItWorksOneImg}
                            alt={"create account graphic"} />
                    </div>

                    <div className={"col-lg-3 col-11 mx-auto mt-3"}>
                        <img className={"full-width-height-image"}
                            src={howItWorksTwoImg}
                            alt={"generate personal library graphic"}
                        />
                    </div>

                    <div className={"col-lg-3 col-11 mx-auto mt-3"}>
                        <img className={"full-width-height-image"}
                            src={howItWorksThreeImg}
                            alt={"create hardcover book graphic"}
                        />
                    </div>

                    <div className={"col-12 "}>
                        <Link to={"/checkout-trial"}>
                            <button onClick={handleClick('Get Started')} className="col-8 col-md-4 btn btn-lg secondary-button mt-3">
                                CREATE A FREE STORY
                            </button>
                        </Link>
                    </div>
                </div>
            </div>


            {/*Text Banner - New Release*/}
            <div className="feature-div-container banner-text ">
                <h3 className={"text-white"}>New Release</h3>
            </div>


            {/*Section - Get Ready For First Best Day*/}

            <div className={"col-12 feature-div-container landing-third-div-container  text-center"}></div>


            {/*Text Banner - Our Range Of Stories*/}
            <div className="feature-div-container banner-text ">
                <h3 className={"text-white"}>Our Range of Stories</h3>
            </div>


            {/*Mobile Section - Product Range*/}
            <div className={"col-12 feature-div-container mobile-landing-product-range"}>
                <div
                    className={"row col-12  mx-auto text-center align-content-center justify-content-center d-sm-block d-md-block d-lg-none mt-5"}>
                    <div className={"mx-auto mt-2"}>
                        <img className={"mx-auto product-image small-rounded mb-3"}
                            src={threeHardCoverBooksImg}
                            alt={"of three hard cover books"} />
                    </div>
                    <div
                        className={"col-6 p-2 text-white font-weight-bold h5 mt-3 mx-auto bg-aqua-green book-promo-banner"}>
                        NEW RELEASE
                    </div>
                    <div className={"text-sm text-black"}>
                        <strong>Digital & Hardcover</strong>
                        <p>Customisable Books</p>
                    </div>

                    <div className={"mx-auto text-center col-12 align-self-end mb-2"}>
                        <Link to={"/checkout-trial"}>
                            <button onClick={handleClick('Explore')} className="col-8 col-md-4 btn btn-lg secondary-button mb-5">
                                CREATE A FREE STORY
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

            {/*Desktop Section - Product Range*/}
            <div className="landing-product-range d-none d-lg-block">
                <div className={"col-12 row mx-auto text-center align-content-center justify-content-center pt-5 "}>
                    <div className={" text-sm col-3 mx-auto mt-5"}>
                        <div>
                            <img className={"product-image small-rounded mb-3"}
                                src={hardCoverBirthdayBookImg}
                                alt="graphic of hardcover book" />
                        </div>
                        <div className={"col-6 p-1 text-white font-weight-bold h5 mx-auto book-promo-banner"}>
                            Personalised
                        </div>
                        <div className={"medium-text text-black"}>
                            <strong>My Brilliant Birthday</strong>
                            <p>Customisable Digital & Hardcover Book</p>
                        </div>
                    </div>
                    <div className={" text-sm col-3 mx-auto mt-5 "}>
                        <div>
                            <img className={"product-image small-rounded mb-3"}
                                src={hardCoverSchoolBookImg}
                                alt="graphic of hardcover book"
                            />
                        </div>
                        <div className={"col-6 p-1 text-white font-weight-bold h5 mx-auto book-promo-banner"}>
                            Personalised
                        </div>
                        <div className={"medium-text text-black"}>
                            <strong>My Fantastic First Day At School</strong>
                            <p>Customisable Digital & Hardcover Book</p>
                        </div>
                    </div>
                    <div className={"text-sm  col-3 mx-auto  mt-5"}>
                        <div>
                            <img className={"product-image small-rounded mb-3"}
                                src={hardCoverZooBookImg}
                                alt="graphic of hardcover book"
                            />
                        </div>
                        <div className={"col-6 p-1 text-white font-weight-bold h5 mx-auto book-promo-banner"}>
                            Personalised
                        </div>
                        <div className={"medium-text text-black"}>
                            <strong>My Amazing Day At The Zoo</strong>
                            <p>Customisable Digital & Hardcover Book</p>
                        </div>
                    </div>
                    <div className={"mx-auto text-center col-12 align-self-end"}>
                        <Link to={"/checkout-trial"}>
                            <button onClick={handleClick('Explore')} className="col-8 col-md-4 btn btn-lg secondary-button mt-5">
                                CREATE A FREE STORY
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <ProductReviewCardsList/>
        </div>
    );
};
export default Home;
