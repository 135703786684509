
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA6T44vuiyMMVjOFWWqrQdkwsv6HaZY-io",
    authDomain: "our-storytime.firebaseapp.com",
    projectId: "our-storytime",
    storageBucket: "our-storytime.appspot.com",
    messagingSenderId: "268520506849",
    appId: "1:268520506849:web:8c1e0442070ca79474fea0",
    measurementId: "G-VNJEZC7B8Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const analytics = getAnalytics(app);
export default app;

